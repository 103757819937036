/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import NextLink from 'next/link';
import { AppContext, AuthContext } from 'Context';
import { CheckRounded } from '@material-ui/icons';
import cn from 'classnames';
import { baseUrl, firebaseCredentials, axios } from 'Api';
import blackLogo from 'Assets/logo-black-bg.png';
import whiteLogo from 'Assets/logo-white-bg.png';
import logo from 'Assets/Login Logo.png';
import { Pathname } from 'Routes';
import AlterImage from 'Assets/alter-image.png';
import { Box } from '@material-ui/core';
import { InfoModal } from '../../../../../../private/dashboard/info-modal/index';
import styles from './index.module.css';
import { TAC } from './TandC';

function Page({
  isFormFilled,
  userEmail,
  children,
  loader,
  navigation,
  paymentChecker,
  continueButtonText,
  formIsFilled,
  onContinue,
  subTitle,
  title,
  purchasingProduct,
  setOpen,
  checked,
  promoErrMsg,
  promoCodeNew,
  promoLoading
}) {
  const { getToken } = useContext(AuthContext);
  const { cardNumber, cvvNumber, expirationDate, isTrail } = useContext(AppContext);
  const [termsAgreed, setTermsAgreement] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    // setOpen(false);
    navigate('/');
  };
  const [isTAC, setIsTAC] = useState(false);
  const handleTandC = () => {
    // alert('tasl');
    setIsTAC(!isTAC);
  };
  const fetchResumeData = async () => {
    try {
      const response = await axios.post(`${baseUrl}/sendToAffiliate`, {
        token: getToken(),
        affiliate_email: userEmail
      });
      navigate('/settings/manage-users');
      // setcategoryLoader(false);
    } catch (error) {
      const responseData = error?.response?.data;
    }
  };
  return (
    <>
      <header className={styles.header}>
        <img
          onClick={isTAC ? handleTandC : goBack}
          className={styles.logo}
          src={logo || AlterImage}
          onError={(e) => {
            e.target.src = AlterImage;
          }}
        />
        <div className={styles.helpCenterButton}>Help Center</div>
      </header>
      {/* <div
      role="button"
      tabIndex={0}
      onClick={() => navigate(navigation)}
      onKeyDown={() => {}}
      className={styles.back}
      >
        BACK
      </div> */}
      {
        isOpenModal && (
          <InfoModal
            isVisible={isOpenModal}
            title="Allow user to pay themselves."
            onHide={() => { setModal(false); }}
            continueButton="Cancel"
            fromPayment="true"
            // content="You are now deleting, all videos and photos will now be a draft until you
            // Add them to a new category. Are you sure you want to continue?"
            actionButton="Send"
            action={() => fetchResumeData()}
            isDelete
          />
        )
      }
      {!isTAC
        ? (
          <div className={styles.page}>
            <h1 className={styles.title}>
              {title}
            </h1>
            <h4 className={styles.subTitle}>
              {subTitle}
            </h4>
            {children}
            <Box>
              <div className={styles.termsContainer}>
                <input
                  hidden
                  id="terms-agreement"
                  onChange={(e) => {
                    setTermsAgreement(e.target.checked);
                  }}
                  type="checkbox"
                  checked={termsAgreed}
                />
                <div
                  className={cn({
                    [styles.termsCheckbox]: true,
                    [styles.checked]: termsAgreed,
                  })}
                  onClick={() => {
                    setTermsAgreement(!termsAgreed);
                  }}
                >
                  {termsAgreed && (
                    <CheckRounded className={styles.termsCheckboxIcon} />
                  )}
                </div>
                <span className={styles.terms}>
                  <label htmlFor="terms-agreement">I agree to the </label>
                  {/* <a onClick={handleTandC}>Projector Terms of Service</a> */}
                  <Link target="_blank" to={Pathname.getSettingsPath(Pathname.termsAndConditions)}>Projector Terms of Service</Link>
                  {/* <NextLink href="#">
              </NextLink> */}
                </span>
              </div>
              <div className={styles.flexMe}>
                <button
                  className={styles.continueButton}
                  disabled={!isFormFilled || loader || purchasingProduct || !termsAgreed || (promoErrMsg && promoCodeNew?.length > 0) || promoLoading || (promoCodeNew?.length > 0 && promoCodeNew?.length < 6)}
                  onClick={onContinue}
                >
                  {loader === true
                    ? (
                      <svg className="loading-circle_mini" width="20" height="20" viewBox="0 0 50 50">
                        <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
                      </svg>
                    ) : purchasingProduct && isTrail === '1' ? 'Starting Trial' : purchasingProduct ? 'Purchasing Plan' : continueButtonText}
                </button>
                {location.pathname === '/pay-by-manger' && !cardNumber && !cvvNumber && !expirationDate ? (
                  <button
                    style={{ display: checked === true && 'none' }}
                    className={styles.continueButton}
                    onClick={() => setModal(true)}
                    disabled={isFormFilled}
                  // type="submit"
                  >
                    Let customer pay
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </Box>
          </div>
        )
        : <TAC />}
    </>
  );
}

export default Page;
