/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cameraRollIcon from 'Assets/camera-roll.svg';
import viewersIcon from 'Assets/viewers.svg';
// import { Button } from 'Components';
import StripeCheckout from 'react-stripe-checkout';
import { config } from 'Config';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import styles from './index.module.css';
import TickCircle from '../../../../Assets/check-circle.png';
import Button from './common/Button';

const AffliatePlan = ({
  product_name,
  sub_title,
  amount,
  priceDetails,
  storage,
  viewers,
  price_id,
  setToken,
  setPriceId,
  setTrial_period_days,
  userIsSignedIn,
  isExtended,
  hasTrial,
  totalTrialDays,
  planId,
  contentId,
  contentType,
  freePlanId,
  color,
  description,
  price_description
}) => {
  const { allPlans, setAppSnackbar, subscriptionDetails, plansPage, setPlansPage,
    currentSelectedPlan, setCurrentSelectedPlan, getSubscription, setUserDetails, userDetails, langText, setIsTrail, isTrail } = useContext(AppContext);
  const { forceAutoSignin } = useContext(AuthContext);
  const location = useLocation();
  const planText = langText?.settings?.billPayment?.planList;
  const commonText = langText?.common;
  const navigate = useNavigate();
  const [money, setMoney] = useState(amount);
  const [size, setSize] = useState(storage);
  const [currentPlanBenefit, setCurrentPlanBenefit] = useState('');
  const [isExtra500, setIsExtra500] = useState(false);
  const [isAddOn, setIsAddon] = useState(false);
  const [selectedPlan, selectPlan] = useState(undefined);
  const [isDisabledUpgrade, setIsDisabledUpgrade] = useState(false);
  const [planViewer, setPlanViewer] = useState(viewers);
  const [planDescription, setPlanDescription] = useState(description);
  const [PriceDescription, setPriceDescription] = useState(price_description);
  const [data, setUpdateData] = useState();
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
  const hideUploadModal = () => { setUploadModalVisibility(false); };
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState({
    subscriptionPlan: subscriptionDetails?.subscription?.plan,
    plan_price: subscriptionDetails?.plan_price,
    plan_price2: subscriptionDetails?.plan_price2,
    forStandard: price_id,
    myPlanName: subscriptionDetails?.current_plan,
    planaction2: subscriptionDetails?.planaction2,
    planaction: subscriptionDetails?.planaction,
    disk_used_mb: 0,
    total_viewer: 0,
    max_storage_mb: 0,
    subscription: (typeof subscriptionDetails?.subscription === 'string'
      || subscriptionDetails?.subscription instanceof String)
      ? subscriptionDetails?.subscription : undefined,
    id: subscriptionDetails?.subscription?.id,
    status: subscriptionDetails?.subscription?.status,
    degrade: subscriptionDetails?.degrade,
    upgrade: subscriptionDetails?.upgrade,
    isNewUser: subscriptionDetails?.is_newUser,
    isFree: subscriptionDetails?.subscription?.is_free,
    hasActivePlan: subscriptionDetails?.has_activePlan,
    paymentMethods: subscriptionDetails?.paymentMethods,
    isAddonAllowed: subscriptionDetails?.is_addon_allowed,
    amount: subscriptionDetails?.subscription?.amount,
    addonRate: subscriptionDetails?.subscription?.addon_rate,
    totalStorageText: subscriptionDetails?.subscription?.total_storage_text,
    totalStorage: subscriptionDetails?.subscription?.total_storage,
    maxAddonAllowedText: subscriptionDetails?.subscription?.max_add_on_allowed_text,
    maxAddonAllowed: subscriptionDetails?.subscription?.max_add_on_allowed,
    isUnlimited: subscriptionDetails?.subscription?.is_unlimited,
    maxViewRequest: subscriptionDetails?.subscription?.max_view_request,
    paymentProcessing: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 4 && subscriptionDetails?.has_activePlan,
    paymentProcessingText: planText?.paymentProcessing || 'Payment Processing',
    paymentRetry: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 2 && subscriptionDetails?.has_activePlan,
    paymentRetryText: planText?.paymentRetry || 'Payment Retry',
    purchasePlanId: subscriptionDetails?.subscription?.purchase_plan_id
  });
  const [openModal, setModal] = useState(false);

  const currentPlanId = subscriptionPlanDetails.id === planId;

  const checkSize = (size) => {
    const bytes = size * 1048576;
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / (1024 ** i)).toFixed(2))} ${units[i]}`;
  };

  const { isValidating: uploadingVisibility, mutate: updateVisibilityContent } = useSWR([
    contentType === 'video' ? endpoints.addVideoContent : endpoints.addPhotoContent, data
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, total_count, message }) => {
      if (success) {
        // setUploadModalVisibility(true);
        // setVideoId(undefined);
        if (contentType === 'video') {
          setUserDetails({ ...userDetails, totalVideoUploaded: 1, totalContentUploaded: 1 });
        } else {
          setUserDetails({
            ...userDetails,
            totalContentUploaded: 1
          });
        }
        hideUploadModal();
        // setTimeout(() => { navigate(Pathname.yourVideos); }, 5000);
        setTimeout(() => { navigate(Pathname.yourVideos); }, 500);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },

  });

  useEffect(() => {
    if (data) {
      updateVisibilityContent();
    }
  }, [data]);

  useEffect(() => {
    if ((subscriptionPlanDetails.max_storage_mb / 1000000 === 6) && product_name === 'Premium') {
      setIsDisabledUpgrade(true);
    }
  }, [size]);
  useEffect(() => {
    setSubscriptionPlanDetails({
      subscriptionPlan: subscriptionDetails?.subscription?.plan,
      plan_price: subscriptionDetails?.plan_price,
      plan_price2: subscriptionDetails?.plan_price2,
      forStandard: price_id,
      myPlanName: subscriptionDetails?.current_plan,
      planaction2: subscriptionDetails?.planaction2,
      planaction: subscriptionDetails?.planaction,
      disk_used_mb: subscriptionDetails?.disk_used_mb,
      total_viewer: subscriptionDetails?.total_viewer,
      max_storage_mb: parseInt(subscriptionDetails?.subscription?.max_storage_mb),
      subscription: (typeof subscriptionDetails?.subscription === 'string'
        || subscriptionDetails?.subscription instanceof String)
        ? subscriptionDetails?.subscription : undefined,
      id: subscriptionDetails?.subscription?.id,
      status: subscriptionDetails?.subscription?.status,
      degrade: subscriptionDetails?.degrade,
      upgrade: subscriptionDetails?.upgrade,
      isNewUser: subscriptionDetails?.is_newUser,
      isFree: subscriptionDetails?.subscription?.is_free,
      hasActivePlan: subscriptionDetails?.has_activePlan,
      paymentMethods: subscriptionDetails?.paymentMethods,
      isAddonAllowed: subscriptionDetails?.is_addon_allowed,
      amount: subscriptionDetails?.subscription?.amount,
      addonRate: subscriptionDetails?.subscription?.addon_rate,
      totalStorageText: subscriptionDetails?.subscription?.total_storage_text,
      totalStorage: subscriptionDetails?.subscription?.total_storage,
      maxAddonAllowedText: subscriptionDetails?.subscription?.max_add_on_allowed_text,
      maxAddonAllowed: subscriptionDetails?.subscription?.max_add_on_allowed,
      isUnlimited: subscriptionDetails?.subscription?.is_unlimited,
      maxViewRequest: subscriptionDetails?.subscription?.max_view_request,
      paymentProcessing: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 4 && subscriptionDetails?.has_activePlan,
      paymentProcessingText: planText?.paymentProcessing || 'Payment Processing',
      paymentRetry: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 2 && subscriptionDetails?.has_activePlan,
      paymentRetryText: planText?.paymentRetry || 'Payment Retry',
      purchasePlanId: subscriptionDetails?.subscription?.purchase_plan_id
    });
    if (currentPlanId && subscriptionPlanDetails.hasActivePlan) {
      setMoney(parseInt(subscriptionPlanDetails.amount) / 100);
      setSize(subscriptionPlanDetails.totalStorageText);
      setCurrentPlanBenefit(`Add ${subscriptionPlanDetails.maxAddonAllowedText} of storage for 
      $${(subscriptionPlanDetails?.addonRate / 100).toFixed(2)}/month`);
      setPlanViewer(subscriptionPlanDetails?.isUnlimited === '1' ? (planText?.unlimited || 'unlimited') : subscriptionPlanDetails?.maxViewRequest);
    }
  }, [subscriptionDetails]);

  const handleChange = (event) => {
    const priceValue = parseFloat(subscriptionPlanDetails.amount) / 100;
    const addon = parseFloat(subscriptionPlanDetails?.addonRate) / 100;
    let space = parseFloat(subscriptionPlanDetails.totalStorage);
    if (isAddOn) {
      setMoney(priceValue);
      setSize(subscriptionPlanDetails.totalStorageText);
    } else {
      setMoney(priceValue + addon);
      space += parseFloat(subscriptionPlanDetails.maxAddonAllowed);
      setSize(checkSize(space));
    }
    setIsAddon(!isAddOn);
  };

  let option;
  const DEGRADE_PLAN = 'Chang';
  const UPGRADE_PLAN = 'Upgrad';
  const isNotFree = subscriptionPlanDetails.subscriptionPlan !== 'Free'
    && subscriptionPlanDetails.subscriptionPlan !== undefined;

  if (subscriptionPlanDetails.degrade?.includes(planId)) {
    option = DEGRADE_PLAN;
  } else if (subscriptionPlanDetails.upgrade?.includes(planId)) {
    option = UPGRADE_PLAN;
  }
  const openTrailClick = (e) => {
    setIsTrail(e);
    navigate('/pay-by-manger');
  };

  return (
<div>
{totalTrialDays !== '0' && totalTrialDays > 0 && (
  <p className={styles.daysTrails}>
    {totalTrialDays}
    {' '}
    Day Trial
  </p>
)}
    <div className={cn({ [styles.col]: true, [styles.plan_blue]: color === 'blue', [styles.eachPlan]: true })}>
      <div>
        <div className={styles.plan_head}>
          <div className={styles.plan_name}>
            <p>{product_name}</p>
            {hasTrial === '1' && (
            <span>
              {totalTrialDays}
              {' '}
              {totalTrialDays === '1' ? 'day ' : 'days '}
              Free trial
            </span>
            )}
          </div>

          <div className={styles.plan_price}>
            <p>
              $
              {amount}
            </p>
            <p>{price_description}</p>
          </div>
        </div>

        {description && (
        <ul className={styles.plan_body}>
          {
            description?.split(',').map((feature, index) => (
              <li key={index}>
                <span className={styles.feat}>{feature}</span>
                <img className={styles.tickster} src={TickCircle} alt="" />
              </li>
            ))
          }
        </ul>
        )}
      </div>

      <div className={styles.feature}>
        {(subscriptionPlanDetails.isAddonAllowed && subscriptionPlanDetails.hasActivePlan && currentPlanId) && (
          <>
            <div className={styles.featureIcon}>
              <Radio
                checked={isAddOn}
                onClick={handleChange}
                value="a"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                color="primary"
                style={{ padding: '0' }}
              />
            </div>
            {/* <img alt="viewers" src={viewersIcon} className={styles.featureIcon} /> */}
            <div
              className={cn({ [styles.featureText]: true, [styles.featureTextWhite]: color === 'blue', [styles.featureTextBlue]: color === 'white' })}
            // className={styles.featureText}
            >
              {currentPlanBenefit}
            </div>
          </>
        )}
      </div>
      <>
      { hasTrial === '1' && (
        <Button
              onClick={() => openTrailClick('1')}
              text="Get Started For Free"
              btnClass={color === 'blue' ? 'white' : 'blue'}
            //   isDisabled={(subscribingPlan || (currentPlanId && subscriptionPlanDetails.paymentProcessing) || (!currentPlanId && (subscriptionPlanDetails.paymentProcessing || subscriptionPlanDetails.paymentRetry)) || paymentRetrying || userDetails?.isSubscription) && !subscriptionDetails?.expired}
        />
        )}
            <Button
              onClick={() => openTrailClick('0')}
              text="Buy Now"
              btnClass={styles.buyNow}
            />
      </>
    </div>
</div>
  );
};
export default AffliatePlan;
