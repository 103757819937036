/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { OTPInput } from 'Components/OTPInput';
import { encryptedKeyForCredentials } from 'Commons';
import { Pathname } from 'Routes';
import { Button } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { isMobile } from 'react-device-detect';
import { AuthPage } from '../components/page';
import { Snackbar } from '../../../../Components/index';
import styles from './verificationCode.module.css';

export const NewVerifyCode = () => {
  const navigate = useNavigate();
  const { setAppSnackbar, userDetails, setUserDetails, plansPage, setPlansPage, langText, setReferralStatus } = useContext(AppContext);
  const { forceAutoSignin, setToken, getToken, setEmailVerified } = useContext(AuthContext);
  const mobileVerificationText = langText?.mobileVerification;
  const commonText = langText?.common;
  const signUpText = langText?.signInUp;
  const OTP_RESEND_TIME = 60;
  const [expireTime, setExpireTime] = useState(OTP_RESEND_TIME);
  const [OTP, setOTP] = useState({ 'one': '', 'two': '', 'three': '', 'four': '', 'five': '' });
  const [otpFinal, setOtpFinal] = useState();
  // const [OTP, setOTP] = useState('');
  const email = sessionStorage.getItem(encryptedKeyForCredentials.email);
  // const mobile = sessionStorage.getItem(encryptedKeyForCredentials.mobile);
  const password = sessionStorage.getItem(encryptedKeyForCredentials.password);
  const [mobile, setMobile] = useState(sessionStorage.getItem(encryptedKeyForCredentials.mobile));
  const [isOtpReached, setOtpReached] = useState(sessionStorage.getItem('#fs656gfhllh@#$'));
  const [first, setfirst] = useState(true);
  const [sendOtpCount, setSendOtpCount] = useState(0);
  const [pastedValue, setPastedValue] = useState('');
  const token = getToken();
  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();
  const fifthInput = useRef();
  const re = /^[0-9\b]+$/;

  const { isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signin, email, password], {
    fetcher: (url, inputEmail, inputPassword) => dataFetcher(url, { email: inputEmail, password: inputPassword }),
    onSuccess: ({ isNewUser, success, data, referral_status, is_skip, message, verify_mobile, mobile: userMobile }) => {
      if (success) {
        sessionStorage.removeItem(encryptedKeyForCredentials.email);
        sessionStorage.removeItem(encryptedKeyForCredentials.password);
        sessionStorage.removeItem(encryptedKeyForCredentials.mobile);
        setUserDetails({ ...userDetails, isNewUser, mobile: userMobile });
        setMobile(userMobile);
        setToken(data?.token);
        setEmailVerified(data?.is_email_verified);
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        forceAutoSignin();
        if ((parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 && !data?.billing_date)) {
          setPlansPage(false);
          parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 ? setReferralStatus(1) : setReferralStatus(0);
           navigate(Pathname.plans);
        } else if (parseInt(data?.verify_mobile, 10) === 0) {
          // has_logged_in === '0' ? navigate(Pathname.gettingStarted) : navigate(Pathname.access);
          navigate(Pathname.authentication.newmobileVerification);
        } else {
          navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}`);
        }
      } else if (!verify_mobile) {
        if (userMobile) {
          //   navigate(Pathname.authentication.verifyCode);
          navigate(Pathname.authentication.newverifyCode);
        } else {
          navigate(Pathname.authentication.newmobileVerification);
        }
      } else {
        setAppSnackbar({ message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      // if ('email_verified' in responseData && !responseData?.email_verified) {
      //   sessionStorage.setItem('emailForValidation_projectoruser', email);
      //   navigate(Pathname.authentication.verifyEmail);
      // } else {
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
      // }
    },
  });
  const defaultSnackbar = { isVisible: false, message: '', type: '' };
  const [snackbarData, setSnackbar] = useState(defaultSnackbar);
  const { isValidating: verifyingOTP, mutate: verifyOtp } = useSWR([endpoints.verifyOtp, otpFinal, email], {
    fetcher: (url, code, email) => dataFetcher(url, { code, email }),
    onSuccess: ({ success, message }) => {
      if (success) {
        if (email) {
          signInUser();
        } else {
          navigate(Pathname.access);
        }
      } else {
        setAppSnackbar({ message: message || 'Oops! Something went wrong', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setSnackbar({ isVisible: true, message: responseData?.message });
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: requestingCode, mutate: requestVerificationCode } = useSWR([endpoints.sendOtpToMobile, email, sessionStorage.getItem('mvt')], {
    fetcher: (url, email, token) => dataFetcher(url, { email, token }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setOtpReached(true);
        setSendOtpCount(1);
        setAppSnackbar({ message: message || 'Otp Resend', isVisible: true });
      } else {
        setAppSnackbar({ message: message || 'Oops! Something went wrong', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  useEffect(() => {
    if (isOtpReached) {
      if (!expireTime) return;
      const intervalId = setInterval(() => {
        setExpireTime(expireTime - 1);
      }, 1000);
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [expireTime, isOtpReached]);

  useEffect(() => {
    if (!sendOtpCount && !isOtpReached) {
      // requestVerificationCode();
    } else {
      setOtpReached(true);
      sessionStorage.removeItem('#fs656gfhllh@#$');
    }
  }, [sendOtpCount, isOtpReached]);

  useEffect(() => {
    setOtpFinal(`${OTP.one}${OTP.two}${OTP.three}${OTP.four}${OTP.five}`);
  }, [OTP]);

  /** Change state of input according to pasted data */
  useEffect(() => {
    if (pastedValue.length === 5) {
      setOTP({ one: pastedValue[0], two: pastedValue[1], three: pastedValue[2], four: pastedValue[3], five: pastedValue[4] });
      setPastedValue('');
      fifthInput?.current.focus();
    }
  }, [pastedValue]);

  const resendCodeHandler = () => {
    setExpireTime(OTP_RESEND_TIME);
    setSendOtpCount(0);
    requestVerificationCode();
  };

  function handleOtpInput(value, otpInputPos, nextInput, prevInput) {
    if (value && value !== '' && nextInput) {
      nextInput?.current.focus();
    } else if ((!value || value === '') && prevInput) {
      prevInput?.current.focus();
    }
    if (value?.length <= 1) { setOTP({ ...OTP, [otpInputPos]: value }); }
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    if (paste.length <= 5) {
      setPastedValue(paste);
    }
  };

  return (
    <>
      <div style={{ marginTop: '-50px' }}>
        <AuthPage smallIcon>
          {
            !isMobile && <div style={{ marginTop: '50px' }} aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{commonText?.back || 'BACK'}</div>
          }
          {
            isOtpReached ? (
              <form onPaste={handlePaste} className={styles.alignForm} onSubmit={(e) => { e.preventDefault(); verifyOtp(); }}>
                <div className={styles.inputLabel}>{mobileVerificationText?.enterYourCode || 'Enter your code'}</div>
                <div className={styles.description}>
                  <div>A code was send to your number, please check and enter below.</div>
                  <div>
                    If you do not see the code please try to resend in &nbsp;
                    <span style={{ color: 'white' }}>
                      {`${expireTime}s`}
                    </span>
                  </div>
                </div>
                {/* <span style={{ textAlign: 'center' }} className={styles.description}>{`A code was send to your number ${mobile || userDetails?.mobile}, please check and enter below. If you do not see the code please try to resend in `}</span>
                <span className={styles.expireTime}>{`${expireTime}s`}</span> */}
                <br />
                <br />
                <div className={styles.buttonBlock}>
                  <div style={{ paddingLeft: '8px', display: 'flex' }}>
                    <div style={{ paddingRight: '18px' }}>
                      <input
                        type="number"
                        max={9}
                        value={OTP.one}
                        ref={firstInput}
                        onChange={(event) => handleOtpInput(event.target.value, 'one', secondInput)}
                        className={styles.codeInput}
                      />
                    </div>
                    <div style={{ paddingRight: '18px' }}>
                      <input
                        type="number"
                        max={9}
                        value={OTP.two}
                        ref={secondInput}
                        onChange={(event) => handleOtpInput(event.target.value, 'two', thirdInput, firstInput)}
                        className={styles.codeInput}
                      />
                    </div>
                    <div style={{ paddingRight: '18px' }}>
                      <input
                        type="number"
                        max={9}
                        value={OTP.three}
                        ref={thirdInput}
                        onChange={(event) => handleOtpInput(event.target.value, 'three', fourthInput, secondInput)}
                        className={styles.codeInput}
                      />
                    </div>
                    <div style={{ paddingRight: '18px' }}>
                      <input
                        type="number"
                        max={9}
                        value={OTP.four}
                        ref={fourthInput}
                        onChange={(event) => handleOtpInput(event.target.value, 'four', fifthInput, thirdInput)}
                        className={styles.codeInput}
                      />
                    </div>
                    <div style={{ paddingRight: '18px' }}>
                      <input
                        type="number"
                        max={9}
                        value={OTP.five}
                        ref={fifthInput}
                        onChange={(event) => handleOtpInput(event.target.value, 'five', null, fourthInput)}
                        className={styles.codeInput}
                      />
                    </div>
                  </div>
                  {/* <input
                    type="tel"
                    maxLength={5}
                    value={OTP}
                    onChange={handleChange}
                    style={{ width: '200px', height: '55px', borderRadius: '10px', backgroundColor: '#31343e', border: '2px solid #6d6f76', color: '#ffffff', fontSize: '20px', fontWeight: '700', textAlign: 'center' }}
                  /> */}
                  {/* <OTPInput className={styles.otpInputs} inputStyles={{ width: '60px', height: '55px', borderRadius: '10px', backgroundColor: '#31343e', border: '2px solid #6d6f76', color: '#ffffff', fontSize: '20px', fontWeight: '700' }} value={OTP} onChange={setOTP} autoFocus OTPLength={5} otpType="number" disabled={false} /> */}
                </div>
                <br />
                <div className={styles.buttonBlock}>
                  <Button isFullWidth blockText isDisabled={!otpFinal || otpFinal?.length !== 5 || verifyingOTP} isBlue isLarge submit>
                    {mobileVerificationText?.continue || 'Continue'}
                  </Button>
                </div>
              </form>
            ) : null
          }
          <div
            className={styles.resendDiv}
          // onClick={() => { setExpireTime(120); setSendOtpCount(0); }}
          >
            <button style={{ borderStyle: 'none', marginTop: '-0px' }} onClick={() => { resendCodeHandler(); }} disabled={expireTime !== 0}>
              {mobileVerificationText?.resendLink || 'Resend Code'}
            </button>
          </div>
          <Snackbar {...snackbarData} onClose={() => { setSnackbar({ ...snackbarData, isVisible: false }); }} />
        </AuthPage>
      </div>

    </>
  );
};
