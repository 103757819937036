export const Pathname = {
  home: '/',
  referral: '/referral',
  promotion: '/promotion',
  plans: '/plans',
  innerpage: '/innerpage',
  purchasePlans: '/plans/:type/:id',
  purchasePlan: (type, id) => (`/plans/${type}/${id}`),
  gettingStarted: '/getting-started',
  profile: {
    basePath: '/profile',
    friendProfile: '/:profileID',
  },
  getFriendProfilePath: (profileID) => (`/profile${(profileID !== undefined) ? `/${profileID}` : ''}`),
  getFriendSearch: (profileID) => (`/search${(profileID !== undefined) ? `/${profileID}` : ''}`),
  getFriendWatchList: (profileID) => (`/watchlist${(profileID !== undefined) ? `/${profileID}` : ''}`),
  getProfilePath: () => ('/profile'),
  watchlist: {
    basePath: '/watchlist',
    friendProfile: '/:profileID',
  },
  search: {
    basePath: '/search',
    friendProfile: '/:profileID',
  },
  access: '/access',
  dashboard: '/dashboard',
  video: '/video/:videoID',
  album: '/album/:albumID',
  getVideoPath: (id) => (`/video/${id}`),
  getAlbumPath: (id) => (`/album/${id}`),
  player: '/player/:videoID/:type',
  albumPlayer: '/albumplayer/:albumID/photo/:photoId',
  getPlayerPath: (id, type) => (`/player/${id}/${type}`),
  getAlbumPlayerPath: (id, photo_id) => (`/albumplayer/${id}/photo/${photo_id}`),
  yourVideos: '/your-videos',
  contentLayout: '/content-layout',
  contentLayoutDashboard: '/content-layout/:layoutType/:id',
  contentYourVideosDashboard: '/your-videos/:layoutType/:id',
  getContentLayoutPath: (layoutType, id) => (`/content-layout/${layoutType}/${id}`),
  getYourVideoLayoutPath: (layoutType, id) => (`/your-videos/${layoutType}/${id}`),
  contentLayoutType: '/content-layout/:layoutType',
  getContentLayoutType: (layoutType) => (`/content-layout/${layoutType}`),
  settings: {
    basePath: '/settings',
    accounts: '/accounts',
    notifications: '/notifications',
    privacy: '/privacy',
    manageUsers: '/manage-users',
    billings: '/billings-and-payments',
    managePaymentMethods: '/manage-payment-methods',
    termsAndConditions: '/terms-of-use',
    subscriberAgreement: '/subscriber-agreement'
  },
  getSettingsPath: (path) => (`/settings${path}`),
  viewers: {
    basePath: '/viewers',
    viewer: '/viewerlists',
    groups: '/groups',
    successors: '/successors',
  },
  getViewersPath: (path) => (`/viewers${path}`),

  projectorStream: '/',
  terms: '/',
  userRights: '/',
  about: '/',
  projectorStreamPartnerProgram: '/',

  authentication: {
    signOut: '#',
    signUp: '/sign-up',
    newSignUp: '/newSign-Up',
    signUp_refferedBy: '/sign-up/:refferedBy',
    signIn: '/sign-in',
    newsignIn: '/newSign-in',
    forgotPassword: '/forgot-password',
    verifyEmail: '/verify-email',
    verifyEmailLink: '/verify-your-email',
    mobileVerification: '/mobile-verification',
    newmobileVerification: '/newMobile-verification',
    verifyCode: '/verify-code',
    newverifyCode: '/newVerification-code',
  },

  updateGroup: '?updateGroup',
  updatingGroup: (id, title) => (`?updateGroup?${id}?${title}`),
  requestAccess: '?request-access',
  grantAccess: '?grant-access',
  createGroup: '?create-group',
  addSuccessor: '?add-successor',
  uploadVideo: '?upload-video',
  checkout: '?checkout',
  cancelSubscription: '?cancel-subscription',
  projectorTermsAndServices: '/terms-and-services',
  termsAndConditions: '/terms-of-use',
  privacy: '/privacy',
  manageDevice: '/manage-device',
  tvLogin: '/tv-login',
  referFriend: '?refer-friend',
  upgradePlan: '?upgrade-plan',
  processingInBackGround: '?processing-in-background'
};
