/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import ListingModal from 'Components/ListingModal';
import { initializeApp } from 'firebase/app';
import { ref, getDatabase, off, onValue, update } from 'firebase/database';
import { useSelector, useDispatch } from 'react-redux';
import { saveDummy, removeDummy, saveUploadInfo } from 'store/store';
import Skeleton from '@material-ui/lab/Skeleton';
import Spinner from 'Components/Spinner';
import { toast } from 'react-toastify';
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DropMe from 'Components/DropMe/DropMe';
import { ALLOWED_UPLOADS_COUNT, PLANS } from 'Commons';
import RotatingLoader from 'Components/RotatingLoader';
import { axios, dataFetcher, endpoints, firebaseCredentials, baseUrl } from 'Api';
import { ReactComponent as AscendingIcon } from 'Assets/ascending.svg';
import { ReactComponent as DescendingIcon } from 'Assets/descending.svg';
import { ReactComponent as DownIcon } from 'Assets/downIcon.svg';
import { ReactComponent as FilterIcon } from 'Assets/filter.svg';
import AlterImage from 'Assets/no-image-available.png';
import Close from 'Assets/close.svg';
import { ReactComponent as UpIcon } from 'Assets/upIcon.svg';
import { Page, Modal } from 'Components';
import { VideoPopupModal } from 'Components/VideoPopup';
import { AppContext, AuthContext } from 'Context';
import useConvertHeicImages from 'hooks/useConvertHeicImages';
import { changeDateFormatToMMDDYY } from 'Utils/utils';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactPaginate from 'react-paginate';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { useUID } from 'react-uid';
import { v4 as uuid } from 'uuid';
import contentDashboardPreviewImage from '../../../Assets/conten_dashboard_prview.png';
import PhotoPreview from '../../../Components/upload-modal/components/PhotoPreview';
import projectorLogo from '../../public/landing-external/assets/img/logo-p.svg';
import { InfoModal } from '../dashboard/info-modal';
import { Checkbox, TableCell, TableHeaderCell } from './components/CustomDesigns';
import PhotoList from './components/PhotoList';
import PrefDropDown from './components/PrefDropDown';
import { EditModal } from './components/upload-modal';
import styles from './index.module.css';
import CategoriesMenuContainer from './menu/CategoriesMenuContainer';
import EditMenu from './menu/EditMenu';
import VisibilityMenu from './menu/VisibilityMenu';
import { DummyRow } from './dummyRow';
import AddDotLoader from './AddDotLoader';
import { UploadModal } from '../../../Components/upload-modal/index';
import ArrowDown from '../../../Assets/arrowdown.png';
import Cross from '../../../Assets/Crossfilter.png';

let tempUploadPercentages;
let tempUploadSizes;

const PreloadData = ({ children }) => (
  <tr className={styles.preLoadDataContainer}>
    <td className={styles.preLoadData} colSpan={7}>
      <div className={styles.preloadDataContent}>{children}</div>
    </td>
  </tr>
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customStyleOnTab: {
    fontSize: '28px',
    color: '#95969A',
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  activeTab: {
    fontSize: '28px',
    color: '#02071A',
    fontWeight: 700,
    textTransform: 'capitalize'
  }
}));
function LoadingTableItem({ items = [], setExpanded }) {
  const [expanded, setIsExpanded] = useState(false);

  function handleExpandCLick() {
    setExpanded((t) => !t);
    setIsExpanded((t) => !t);
  }

  return (
    <Box width="100%">
      <Box display="flex" padding={1} alignItems="center">
        <Box display="flex" padding={1} alignItems="center" margin={1} bgcolor="black" borderRadius="10px">
          <img src={projectorLogo} style={{ width: '50px', height: '50px' }} alt="projector logo" />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box>
            <Typography className={styles.progressIndicatorTitle}>
              Uploading in In Progress
            </Typography>
            <Typography className={styles.progressIndicatorSubTitle}>
              {items?.length}
              {' '}
              items being uploaded
            </Typography>
          </Box>

        </Box>
        {/* eslint-disable-next-line */}
        <Box onClick={handleExpandCLick}>
          {
            expanded
              ? <ExpandLessOutlined color="primary" className={styles.expand} />
              : <ExpandMoreOutlined color="primary" className={styles.expand} />
          }
        </Box>
      </Box>
    </Box>
  );
}

const YourVideos = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const query = new URLSearchParams(window.location.search);
  const showModal = query.get('showModal');
  const [modalShowPopUp, setModalShowPopUp] = useState(showModal);
  const { setHoldTheWeb, setTopOrBottom, userDetails, subscriptionDetails,
    langText, singleEdit, setSingleEdit, setUploadPercentagesInNumber,
    setAppSnackbar, getSubscription, setDraftContent, closeModal: closeUploadModal,
    selectedItems, setSelectedItems, getAccessChecker, eta, setIamUploading, textOfUpload,
    firebaseDatabase, autoSignIn, setUploadContainer, setNeedToShowETA, uploadQueue } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const profileID = userDetails.myId;
  const [timer, setTimer] = useState(null);
  const [selectedVideo, selectVideo] = useState(undefined);
  const [videoData, setVideoData] = useState(undefined);
  const [handlingAction, setActionHandlingStatus] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [editMenu, setEditMenu] = useState(undefined);
  const [visibilityMenu, setVisibilityMenu] = useState(undefined);
  const [categoriesMenu, setCategoriesMenu] = useState(false);
  const [content, setContent] = useState([]);
  const [showImage, setShowImage] = useState(null);
  const [albumImages, setAlbumImages] = useState(undefined);
  const [selectedType, selectType] = useState(undefined);
  const contentText = langText?.content;
  const commonText = langText?.common;
  const [switchPoint, setswitchPoint] = useState(false);
  const [tabTitle, setTabTitle] = useState(parseInt(window.sessionStorage.getItem('tabTitle'), 10) || 0);
  const [allContent, setAllContent] = useState(undefined);
  const [tableContentLoading, setTableContentLoading] = useState(false);
  const [isOpenPopupModal, setOpenPopupModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [callAlbum, setCallAlbum] = useState([]);
  const [order, setOrder] = useState({
    name: 0,
    date: 0,
    status: 0
  });
  const [pageCount, setPageCount] = useState(1);
  const [sortBy, setSortBy] = useState('date');
  const classes = useStyles();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [openEditVideoModal, setEditVideoModal] = useState(false);
  const [contentDetails, setContentDetails] = useState(undefined);
  const [cancelSelection, setCancelSelection] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [paginatedData, setPaginatedData] = useState(undefined);
  const [selectedPage, selectPage] = useState(1);
  const [isOpenModal, setModal] = useState(false);
  const [isOpenModalinClick, setOpenModalinClick] = useState(false);
  const [isOpenVideoPopup, setOpenVideoPopup] = useState(true);
  const [videoProcessingList, setVideoProcessingList] = useState([]);
  const [albumProcessingList, setAlbumProcessingList] = useState([]);
  const [progressListExpanded, setProgressListExpanded] = useState(false);
  const [videodraftid, setVideodraftid] = useState('');
  const [videodraftTitle, setVideodraftTitle] = useState([]);
  const [selectedIdType, setSelectedIdType] = useState();
  const [selectedVideoContent, setSelectedVideoContent] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [isDummyLoader, setIsDummyLoader] = useState(false);
  const loadingTexts = ['...', '..', '.'];
  const [editLoader, setEditLoader] = useState(false);
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const [showText, setShowText] = useState(true);
  const uploadPercentages = useSelector((state) => state.upload);
  const uploadSizes = useSelector((state) => state.uploadSizes);
  const dummyObject = useSelector((state) => state.dummy);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [editItems, setEditItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(''); // State to hold the selected value
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [categrory, setCategrory] = useState(undefined);
  const [editItemsStatus, setEditItemsStatus] = useState([]);
  const [selectedDraftIds, setSelectedDraftIds] = useState([]);
  const [selectedContentIds, setSelectedContentIds] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canICall, setCanICall] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const temp = {};
  const { isValidating: fetchingGetMyContent, mutate: fetchContent } = useSWR([endpoints.getMyContentsBasedOnUploadDate, profileID, order[sortBy], sortBy, selectedPage, searchTerm, selectedOption?.value], {
    fetcher: (url, user_id, order_by, sort_by, page, search, category) => dataFetcher(url, { user_id, order_by, sort_by, page, search, category }),
    onSuccess: ({ success, data, paginate }) => {
      setDataLoader(false);
      setLoading(false);
      setSelectAllChecked(false);
      if (success) {
        // if (!isEqual(allContent, data)) {
        setAllContent(data);
        setTimeout(() => {
          setIsContentLoaded(true);
        }, 1000);
        // }
        setPaginatedData(paginate);
        setVideoProcessingList(tabTitle === 0 ? data?.videos_in_progress : data?.albums_in_progress);
        setAlbumProcessingList(data?.albums_in_progress);
        setEditItems([]);
        setEditItemsStatus([]);
        setSelectedItems([]);
        setSelectedDraftIds([]);
          }
    },
    onError: () => {
    },
  });
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const databaseRefD = ref(firebaseDatabase, 'local-transcorder');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  const closeToast = (draft_id, message, type) => {
    toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
    setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
  };
  const dispatch = useDispatch();
  const updateDummy = (dummy, status) => {
    if (status === 1) {
      dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
    } else {
      dispatch(removeDummy(dummy));
    }
  };
  const tempRef = useRef({});
  const fetchData = () => {
    const uuidparam = uuid();
    const allFalse = Object.values(tempRef.current).every((value) => value === false);
    if (allFalse) {
      tempRef.current[uuidparam] = true;
        fetchContent()
            .then((res) => {
              tempRef.current[uuidparam] = false;
            })
            .catch((error) => {
              tempRef.current[uuidparam] = false;
            });
    }
};
const updateUploadPercentages = (draft_id, data) => {
  dispatch(saveUploadInfo({ [draft_id]: data }));
};
  useEffect(() => {
    fetchData();
  }, [dummyObject]);
  const handleEncouter = (draft_id) => {
    const deletePromise = axios
          .post(`${baseUrl}/deleteDraftContent`, {
            token: getToken(),
            draft_id: [draft_id],
            content_id: [],
          })
          .then((response) => {
            // fetchContent();
            updateDummy(draft_id, 0);
            changeFieldFromFirebase(draft_id, 'DELETED');
            updateUploadPercentages(draft_id, 'Cancelled');
            closeToast(draft_id, 'Upload has been canceled by user', 'error');
            return response;
          })
          .catch((error) => {
            updateDummy(draft_id, 0);
            console.error(`Error deleting items for draftId ${draft_id}:`, error);
            throw error;
          });
  };
  // temp add
  useEffect(() => {
    setUploadPercentagesInNumber(uploadPercentages);
// const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
//     if (allPropertiesAreNumericOrString) {
//         setIamUploading(false);
//       } else setIamUploading(true);
    const handleBeforeUnload = (e) => {
      const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
      if (allPropertiesAreNumericOrString) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadPercentages]);

  // temp end
  /**
   * The draft array where progressing videos with are shown
   * The videodraftcontent where corresponsidng content of draft is shown
   * theres video_id and conte_id both same in these array, will be checkig both for some conditoins
   */
  const mergeDrafts = useMemo(() => {
    if (!allContent || !allContent?.videoDraftContents || !allContent?.draft) {
      return [];
    }

    const mergedItems = [];
    const draftLookup = {};

    if (allContent && allContent.videoDraftContents && allContent.draft) {
      allContent.draft.forEach((draft) => {
        draftLookup[draft.content_id] = draft;
      });

      allContent.videoDraftContents.forEach((videoDraftContent) => {
        const draftContent = draftLookup[videoDraftContent?.video_id];
        const mergedItem = draftContent ? { ...videoDraftContent, ...draftContent } : videoDraftContent;
        mergedItems.push(mergedItem);
      });
    }
    return allContent?.videoDraftContents;
  }, [allContent]);
  const { isValidating: deletingVideo, mutate: deleteVideo } = useSWR([selectedType
    ? endpoints.deleteMyAlbum : (tabTitle === 2 || (tabTitle === 0 && !videoData?.status)) && endpoints.deleteVideo, tabTitle === 2 ? videoData?.draft_id : !singleEdit ? selectedItems || videoData?.content_id : videoData?.content_id || selectedItems], {
    fetcher: (url, id) => dataFetcher(url, selectedType ? { album_id: id } : (tabTitle === 2 || (tabTitle === 0 && !videoData?.status)) ? { draft_id: id } : { video_id: id }),
    onSuccess: ({ success, data }) => {
      if (success) {
        // fetchContent();
        getSubscription();
        setModal(false);
        selectVideo(null);
        setTimeout(() => {
          setDataLoading(false);
        }, 2000);
      }
    },
    onError: () => {
      // fetchContent();
      getSubscription();
      setModal(false);
      selectVideo(null);
      setSelectAllChecked(false);
      setEditItems([]);
      setEditItemsStatus([]);
      setSelectedItems([]);
      setSelectedDraftIds([]);
      setSelectedContentIds([]);
      setTimeout(() => {
        setDataLoading(false);
      }, 2000);
     },
  });
  /**
   * API for fetching selected content details
   *
   */

  const { isValidating: gettingContentDetails, mutate: getContentDetails } = useSWR([tabTitle === 0 ? content?.length && content[0].status ? endpoints.getVideosDetail : endpoints.getVideoDraftDetail : tabTitle === 2 ? endpoints.getVideoDraftDetail : endpoints.getMyAlbumDetail, selectedVideo || selectedItems[0]], {
    fetcher: (url, id) => dataFetcher(url, tabTitle === 0 || tabTitle === 2 ? { id } : { album_id: id }),
    onSuccess: ({ success, data }) => {
      if (success) {
        let newData = tabTitle === 0 || tabTitle === 2 ? data : data[0];
        let matchdata = {};
        if (tabTitle === 2) {
          matchdata = allContent?.videoDraftContents.find((x) => newData?.id === (x?.draft_content_id));
          newData = {
            ...newData,
            description: matchdata.video_description,
            title: matchdata.title,
            playlist_id: matchdata.playlist_id,
            visibility: matchdata.visibility,
            category_id: matchdata.category_id
          };
        }
        if (newData?.draft_id && newData?.draft_id?.slice(0, 9) !== 'old_video') {
          setSelectedDraftIds([newData?.draft_id]);
        } else {
          setSelectedContentIds([newData?.id]);
        }
        setContentDetails(newData);
        if (!singleEdit) {
          setEditLoader(false);
          setEditVideoModal(true);
        }
        setIsDummyLoader(false);
        if (isOpenModalinClick) {
          // setEditVideoModal(true);
        }
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const fetchAlbumData = (content_id) => {
    setEditLoader(true);
    selectVideo(content_id);
    setswitchPoint(!switchPoint);
  };

  const showAlbumImages = (content_id) => {
    setEditLoader(true);
    const apiUrl = `${baseUrl}/getMyAlbumDetail`;
    const requestData = {
      album_id: content_id,
      token: getToken(),
    };
    axios.post(apiUrl, requestData)
      .then((response) => {
        setCallAlbum(response.data?.data);
        setEditLoader(false);
        setEditVideoModal(false);
      })
      .catch((error) => {
        setEditLoader(false);
        console.error('Error:', error);
      });
  };
  const handleButtonClick = (theId, status = null) => {
    setEditLoader(true);
    const requestBody = {
      id: theId,
      token: getToken()
    };
    const f = content.filter((item) => (item?.content_id === theId));
    const apiEndpoint = tabTitle === 0 && f[0]?.status ? `${baseUrl}/getVideosDetail` : `${baseUrl}/getVideoDraftDetail`;
    axios.post(apiEndpoint, requestBody)
      .then((response) => {
      setSelectedVideoContent(content?.filter((item) => (tabTitle === 2 ? item?.draft_content_id : (item?.content_id || item?.video_id)) === theId));
      setContentDetails(response?.data?.data);
      setEditLoader(false);
      setEditVideoModal(true);
      })
      .catch((error) => {
        setEditLoader(false);
        setEditVideoModal(true);
        console.error('Error fetching content details:', error);
      });
  };
  useEffect(() => {
    Aos.init({ duration: 500, once: true });
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      getContentDetails();
      setIsDummyLoader(true);
    }
  }, [selectedVideo, switchPoint]);

  useEffect(() => {
    if (modalShowPopUp && modalShowPopUp === '1'
      && !userDetails?.isSubscription) {
      setOpenPopupModal(true);
    }
  }, [modalShowPopUp, subscriptionDetails]);

  useEffect(() => {
    tempUploadPercentages = uploadPercentages;
  }, [uploadPercentages]);

  useEffect(() => {
    tempUploadSizes = uploadSizes;
  }, [uploadSizes]);
  const onDataChange = (id, changes = []) => {
    const changeObjects = {};

    changes.forEach(([key, value]) => {
      changeObjects[key] = value;
    });

    setVideoList(videoList.map((d) => ({ ...d, ...(id === d.id) && changeObjects })));
  };

  const onMenuClose = () => {
    if (!handlingAction) {
      setCategoriesMenu(false);
      setEditMenu(undefined);
      setVisibilityMenu(undefined);
    }
  };

  function groupProcessing(array) {
    const noStatusItems = [];
    const itemsWithStatus = [];

    array?.length && array?.forEach((item) => {
      if (item.status === undefined) {
        noStatusItems.push(item);
      } else {
        itemsWithStatus.push(item);
      }
    });

    const sortedArray = [...noStatusItems, ...itemsWithStatus];
    // Find duplicate draft_id values
    const draftIds = sortedArray.reduce((acc, item) => {
      acc[item.draft_id] = (acc[item.draft_id] || 0) + 1;
      return acc;
    }, {});

    // Filter out items with duplicate draft_id and no status
    const filteredData = sortedArray.filter(
      (item) => !(draftIds[item.draft_id] > 1 && !item.status)
    );

    return filteredData;
  }
  const checkIfDraftIdExists = (allContent = {}, currentDummyObject) => {
    const {
      album = [],
      draft = [],
      video = [],
      videoDraftContents = []
    } = allContent;
    const draftId = currentDummyObject?.draft_id;
    // Check if the draft_id exists in any of the arrays (album, draft, video, videoDraftContents)
    return (
      (Array.isArray(album) && album.some((item) => item?.draft_id === draftId))
      || (Array.isArray(draft) && draft.some((item) => item?.draft_id === draftId))
      || (Array.isArray(video) && video.some((item) => item?.draft_id === draftId))
      || (Array.isArray(videoDraftContents) && videoDraftContents.some((item) => item?.draft_id === draftId))
    );
  };
  const addDummyObject = () => {
    // if (dummyObject && selectedIdType === undefined) {
    //   const hasDraftID = content?.some((item) => item?.draft_id === dummyObject?.draft_id);
    //   if (!hasDraftID) {
    //     if (content && dummyObject?.type === tabTitle && searchTerm === '') {
    //       setContent((prevContent) => [dummyObject, ...prevContent]);
    //       fetchContent();
    //     }
    //   } else {
    //     const needUpdatedContent = content.some((item) => (item?.draft_id === dummyObject?.draft_id && item?.title === null));
    //     if (needUpdatedContent) {
    //       const updatedContent = content.filter((item) => (item?.draft_id !== dummyObject?.draft_id && item?.title !== null));
    //       setContent(updatedContent);
    //     }
    //   }
    // }
    if (dummyObject && selectedIdType === undefined) {
      Object.keys(dummyObject).forEach((key) => {
        const currentDummyObject = dummyObject[key];
        const hasDraftID = content?.some((item) => item?.draft_id === currentDummyObject?.draft_id);
        if (!hasDraftID) {
          if (content && currentDummyObject?.type === tabTitle && searchTerm === '') {
            setContent((prevContent) => [currentDummyObject, ...prevContent]);
            // fetchContent();
          }
        } else {
          const needUpdatedContent = content.some((item) => (item?.draft_id === currentDummyObject?.draft_id && item?.title === null));
          const isDraftIdPresent = checkIfDraftIdExists(allContent, currentDummyObject);
          if (isDraftIdPresent) {
            dispatch(removeDummy(currentDummyObject?.draft_id));
          }
          if (needUpdatedContent) {
            const updatedContent = content.filter((item) => (item?.draft_id !== currentDummyObject?.draft_id && item?.title !== null));
            setContent(updatedContent);
          }
        }
      });
    }
  };

  const toggleVideoSelection = (id, data, type, isEditing) => {
    setSelectAllChecked(false);
    setEditItems([]);
    setEditItemsStatus([]);
    setSelectedItems([]);
    setSelectedDraftIds([]);
    setSelectedContentIds([]);
    setTableContentLoading(false);
    setVisibilityMenu(undefined);
    setEditMenu(undefined);
    setCategoriesMenu(false);
    setSingleEdit(true);
    if (!singleEdit) {
      selectVideo();
    }
    if (tabTitle === 1) {
      setContent(allContent?.album);
    } else if (tabTitle === 0) {
      setContent(groupProcessing(allContent?.video.filter((obj) => obj.content_status !== 'Draft')));
    } else if (tabTitle === 2) {
      setContent(mergeDrafts);
    }
    // if already selected deselects
    if (selectedVideo === id) {
      selectVideo(undefined);
      setVideoData(undefined);
      selectType(undefined);
      setSelectedVideoContent();
    } else {
      selectVideo(id);
      setVideoData(data);
      selectType(type);
      setSelectedVideoContent(content?.filter((item) => (tabTitle === 2 ? item?.draft_content_id : (item?.content_id || item?.video_id)) === id));
      setContent(content?.filter((item) => (tabTitle === 2 ? item?.draft_content_id : (item?.content_id || item?.video_id)) === id));
    }
    if (isEditing) {
      setOpenModalinClick(isEditing);
      // setEditVideoModal(true);
    }
  };

  useEffect(() => { if (editMenu) { setCategoriesMenu(undefined); } }, [editMenu]);

  useEffect(() => { if (categoriesMenu) { setEditMenu(undefined); } }, [categoriesMenu]);

  useEffect(() => {
    setVisibilityMenu(undefined);
  }, []);
  useEffect(() => {
    const databaseRefGetVideos = ref(firebaseDatabase, `videos_updates/${localStorage.getItem('id')}`);
    const dataCallbackVideos = (snapshot) => {
      if (snapshot.exists()) {
        fetchData();
      }
    };
    onValue(databaseRefGetVideos, dataCallbackVideos);
    return () => {
      off(databaseRefGetVideos, 'value', dataCallbackVideos);
    };
  }, []);
  useEffect(() => {
    const databaseRefGetPhotos = ref(firebaseDatabase, `photo_album_updates/${localStorage.getItem('id')}`);
    const dataCallbackPhotos = (snapshot) => {
      if (snapshot.exists()) {
        fetchData();
      }
    };
    onValue(databaseRefGetPhotos, dataCallbackPhotos);
    return () => {
      off(databaseRefGetPhotos, 'value', dataCallbackPhotos);
    };
  }, []);
  useEffect(() => {
    const databaseRefGetVideosDraft = ref(firebaseDatabase, `draft_videos_updates/${localStorage.getItem('id')}`);
    const dataCallbackVideosDraft = (snapshot) => {
      if (snapshot.exists()) {
        fetchData();
      }
    };
    onValue(databaseRefGetVideosDraft, dataCallbackVideosDraft);
    return () => {
      off(databaseRefGetVideosDraft, 'value', dataCallbackVideosDraft);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [sortBy, order, selectedPage, tabTitle, closeUploadModal, searchTerm, textOfUpload]);

  useEffect(() => {
    if (cancelSelection) {
      if (tabTitle === 1) {
        setContent(allContent?.album);
      } else if (tabTitle === 0) {
        setContent(groupProcessing(allContent?.video.filter((obj) => obj.content_status !== 'Draft')));
      } else if (tabTitle === 2) {
        setContent(mergeDrafts);
      }
      setCancelSelection(false);
    }
  }, [cancelSelection]);

  useEffect(() => {
    setTableContentLoading(false);
    if (tabTitle === 1) {
      setContent(allContent?.album);
    } else if (tabTitle === 0 && !openEditVideoModal) {
      setContent(groupProcessing(allContent?.video.filter((obj) => obj.content_status !== 'Draft')));
    } else if (tabTitle === 2 && allContent && allContent?.videoDraftContents) {
      const mergedContent = mergeDrafts || [];
      setContent(mergedContent.sort((a, b) => new Date(b.created) - new Date(a.created)));
      if (allContent && allContent?.videoDraftContents?.length > 0 && allContent?.videoDraftContents[0]?.video_id === null) {
        // fetchContent();
      }
      // if video already selected and when rerender happens make the selection persist
      if (selectedVideo) {
        const alreadySelectedItem = content?.filter((item) => (tabTitle === 2 ? item?.draft_content_id : (item?.content_id || item?.video_id)) === selectedVideo);
        setContent(alreadySelectedItem);
        if (!alreadySelectedItem) {
          selectedVideo(null);
        }
      }
    }
    setDraftContent(mergeDrafts);
  }, [tabTitle, allContent]);

  useEffect(() => {
    if (tabTitle === 1) {
      setPageCount(parseInt(paginatedData?.album?.no_of_pages, 10));
    } else if (tabTitle === 0) {
      setPageCount(parseInt(paginatedData?.video?.no_of_pages, 10));
    } else if (tabTitle === 2) {
      setPageCount(parseInt(paginatedData?.draft?.no_of_pages, 10));
    }
  });
  useEffect(() => {
    const videosPage = document.getElementById('videos-page');

    const onScroll = () => {
      const preferencesBar = document.getElementById('menu-bar-table');

      if (preferencesBar?.offsetTop !== undefined) {
        if (window.pageYOffset > preferencesBar?.offsetTop) {
          videosPage?.classList.add(styles.fixedPreferenceContainer);
          videosPage.style.setProperty('--menu-bar-width', `${videosPage.offsetWidth}px`);
        } else {
          videosPage?.classList.remove(styles.fixedPreferenceContainer);
        }
      } else {
        videosPage?.classList.remove(styles.fixedPreferenceContainer);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => { window.removeEventListener('scroll', onScroll); };
  }, []);

  /**
   * Handler for sorting in subtitle
   *
   */
  const makeEveryThingNull = () => {
    setEditMenu(undefined);
    setSelectAllChecked(false);
    setEditItems([]);
    setEditItemsStatus([]);
    setSingleEdit(false);
    setSelectedContentIds([]);
    setSelectedDraftIds([]);
    setVisibilityMenu(false);
    setSelectedItems([]);
    setCategoriesMenu(false);
    selectVideo(undefined);
    setCancelSelection(true);
  };

  const [removeSort, setRemoveSort] = useState(false);
  const sortHandler = (sortType) => {
    if (sortType !== 'category') {
     setDataLoader(true);
    }
    setRemoveSort(true);
    setLoading(true);
    // setFilter(true);
    setSortBy(sortType);
    selectPage(1);
    const orderCopy = { ...order };
    if (order[sortType] === 1) {
      orderCopy[sortType] = 0;
    } else {
      orderCopy[sortType] = 1;
    }
    setOrder(orderCopy);
    setTimeout(() => {
      setLoading(false);
    }, 4000); // Replace 2000 with the actual time it takes to sort the table
  };
  const publishedInfo = `A video is considered Published once you have given it a title,
   description, assigned Categories and a Group who has access to view it`;
  const draftInfo = `A video is considered a Draft if you have not given it a title, 
  description, assigned Categories and a Group who has access to view it`;
  const privateInfo = 'A video is considered Private if it is only visible to you';
  /**
   * Handler for heading selection
   *
   */
  const resetFilters = () => {
    setRemoveSort(false);
    // setFilter(false);
    setLoading(true);
    // setDataLoader(true);
    setOrder((prevOrder) => ({
      ...prevOrder,
      status: 0
    }));
    setSortBy('date');
  };
  const handleSearchInputChange = (event) => {
    resetFilters();
   selectPage(1);
    setSelectAllChecked(false);
    setSelectedItems([]);
    setSelectedDraftIds([]); // Clear the selected draft IDs
    setSelectedContentIds([]);
    setSearchTerm(event?.target?.value);
  };
  const performSearch = () => {
    setSearchTerm('');
    setSelectAllChecked(false);
    setSelectedItems([]);
    setSelectedDraftIds([]); // Clear the selected draft IDs
    setSelectedContentIds([]);
  };
  const handleTabChange = (event, newValue) => {
    if (newValue !== tabTitle) {
      resetFilters();
      setSelectedItems([]);
      performSearch();
      setEditItems([]);
      setEditItemsStatus([]);
      setSelectAllChecked(false);
      setSelectedContentIds([]);
      setSelectedDraftIds([]);
      sessionStorage.removeItem('tabTitle');
      selectPage(1);
      setContent(null);
      setTabTitle(newValue);
      selectVideo(undefined);
    }
  };
  const { isValidating: fetchingCategories, mutate: fetchCategories } = useSWR([endpoints.getMyCategories], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      setIsDropdownOpen(!isDropdownOpen);
      if (success) {
        setCategrory(data?.map(({ title: name, id: value }) => ({ name, value })));
      }
    },
    onError: () => { },
  });

  const options = [
    { label: 'Cancel Selection', value: '' }, // First option to cancel selection
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSelect = (value) => {
    setSelectedOption(value);
    setIsDropdownOpen(false); // Close dropdown on selection
  };

  const SubTitle = ({ titleName, sortType, isTitle }) => (
    <div
      onClick={() => sortHandler(sortType)}
      className={cn({ [styles.flexCenter]: true, [styles.flexLeft]: isTitle })}
      style={{ cursor: 'pointer' }}
    >
      {titleName !== 'Category' && titleName}
      &nbsp;&nbsp;
      {titleName === 'Category' ? (
        <div className={styles.customDropdown}>
          {selectedOption && (
<span className={styles.selectedOption}>
   {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
   <CancelIcon
      className={styles.closeButton}
      style={{ marginTop: '10px' }}
      onClick={() => { resetFilters(); handleSelect(''); }}
   />
</span>
)}
{/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
      <div className={styles.dropdownHeader} onClick={fetchCategories}>
      {selectedOption?.name ?? titleName}
{/* {selectedOption && (
<span className={styles.selectedOption}>
      {`(${selectedOption?.name})`}
</span>
)} */}
        <span className={`${styles.dropdownArrow} ${isDropdownOpen ? styles.open : ''}`}>
          {
            sortType === sortBy ? (
              order[sortBy] === 1 ? <AscendingIcon /> : <DescendingIcon />
            ) : null
          }
        </span>
      </div>
      {isDropdownOpen && (
        <ul className={styles.dropdownList}>
          {categrory?.map((option, index) => (
            <li
              key={index}
              className={`${styles.dropdownItem} ${selectedOption === option?.value ? styles.selected : ''}`}
              onKeyPress={undefined}
              // eslint-disable-next-line
              role="button"
              tabIndex={0}
              onClick={() => handleSelect(option)}
            >
              {option?.name}
            </li>
          ))}
        </ul>
      )}
        </div>
      ) : (
        sortType === sortBy ? (
          order[sortBy] === 1 ? <AscendingIcon /> : <DescendingIcon />
        ) : null
      )}
    </div>
  );

  const hideModal = (val) => {
    if (val === 'edit') {
      selectVideo(undefined);
      setVideoData(undefined);
      selectType(undefined);
      // fetchContent();
    }
    // fetchContent(); called to update list
    setEditVideoModal(false);
  };

  const changeDateFormat = (date) => {
    if (date) {
      const [year, month, day] = date?.split('-');
      return (`${month}/${day}/${year}`);
    }
  };

  const downloadVideo = () => {
    setDownloading(true);
    axios({
      url: endpoints.downloadVideo,
      method: 'POST',
      data: { video_id: window.btoa(videoData?.content_id), token: getToken() },
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${videoData?.title}.${contentDetails?.extension}`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setTimeout(() => { setDownloading(false); }, 1000);
    });
  };

  const handlePageClick = (event) => {
    setDataLoader(true);
    setSelectedItems([]);
    setSelectAllChecked(false);
    selectPage(parseInt(event.selected, 10) + 1);
  };

  function changeColorhover(e) {
    e.target.style.fill = 'red';
  }

  function changeColorleave(e) {
    e.target.style.fill = 'white';
  }

  const doCancelAction = () => {
    // onHide();
  };
  const [imageErrorOn, setImageLoadErrorOn] = useState([]);
  function handleImageError(e, indx) {
    const ar = imageErrorOn;
    setImageLoadErrorOn(ar.concat(indx));
  }

  // Get the URL query parameters
  const params = new URLSearchParams(window.location.search);
  // Get the value of the "tab" parameter
  const tabParam = params.get('tab');
  const navigate = useNavigate();

  useEffect(() => {
    if (tabParam === 'draft') {
      fetchData();
      selectVideo(undefined);
      setContent(mergeDrafts);
      // Perform actions specific to the "draft" tab
      setTabTitle(2);
      // Remove the 'tab' parameter from the URL
      params.delete('tab');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl, { replace: true });
      setVideoData(undefined);
      selectType(undefined);
    }
    if (tabParam === 'publish') {
      fetchData();
      // Perform actions specific to the "draft" tab
      selectVideo(undefined);
      setTabTitle(0);
      // Remove the 'tab' parameter from the URL
      params.delete('tab');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl, { replace: true });
    }
  }, [params]);
  useEffect(() => {
    if (tabParam === 'tabtitle3' && mergeDrafts?.length) {
      setTabTitle(2);
      setContent(mergeDrafts);
      // // Remove the 'tab' parameter from the URL
      params.delete('tab');
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      navigate(newUrl, { replace: true });
    }
  }, [allContent, mergeDrafts, content]);

  const [data, setData] = useState([]);
  const [dataD, setDataD] = useState([]);
  useEffect(() => {
    const dataCallback = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setData(dataObj);
      }
    };
    onValue(databaseRef, dataCallback);
    return () => {
      off(databaseRef, 'value', dataCallback);
    };
  }, []);

  useEffect(() => {
    const dataCallback = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setDataD(dataObj);
      }
    };
    onValue(databaseRefD, dataCallback);
    return () => {
      off(databaseRefD, 'value', dataCallback);
    };
  }, []);

  const renderContent = (draft_id, content_status, status, video_id, transcoder_status, workflow_status) => {
    const isInQueue = uploadQueue.some((item) => item.draftId === draft_id);
    let result;
    if (draft_id?.slice(0, 9) !== 'old_video') {
      if (typeof uploadPercentages[draft_id] === 'number') {
        result = <AddDotLoader text={`Uploading ${tempUploadPercentages[draft_id]}%`} />;
      } else if (transcoder_status != null && transcoder_status !== 'Finished') {
        result = <AddDotLoader text="Processing" />;
      } else if (status && workflow_status === 'Complete') {
        switch (content_status) {
          case 'Public':
            result = 'All Viewers';
            break;
          default:
            result = content_status;
            break;
        }
      } else if (tabTitle === 2) {
        result = video_id ? 'Completed' : tempUploadPercentages[draft_id] === 'Starting Upload' || tempUploadPercentages[draft_id] === 'Please wait' ? <AddDotLoader text={tempUploadPercentages[draft_id]} /> : tempUploadPercentages[draft_id] === undefined && isInQueue ? 'Your upload is in queue' : 'Failed to Upload';
      } else if (tempUploadPercentages[draft_id] === 'Starting Upload' || tempUploadPercentages[draft_id] === 'Please wait') {
        result = <AddDotLoader text={tempUploadPercentages[draft_id]} />;
      } else if (tempUploadPercentages[draft_id] === undefined && isInQueue) {
        result = 'Your upload is in queue';
      } else {
        result = 'Failed to Upload';
      }
    } else {
      result = tabTitle === 2 ? (video_id ? 'Completed' : 'In Progress') : tabTitle === 0 ? status ? content_status : 'Processing' : content_status;
    }
    // if (draft_id?.slice(0, 9) !== 'old_video') {
    //   if (data[draft_id]) {
    //     if (data[draft_id].status === 'STATUS_UPDATE') {
    //       result = 'Public';
    //     } else if (data[draft_id].status === 'ERROR' || data[draft_id].status === 'COMPLETE' || data[draft_id].status === 'COMPLETED' || data[draft_id].status === 'NEW_WARNING' || data[draft_id].status === 'INPUT_INFORMATION') {
    //       if (tabTitle === 2) {
    //         result = 'Completed';
    //       } else if (content_status !== '' && content_status !== undefined) {
    //         result = content_status;
    //       } else {
    //         // result = <AddDotLoader text="Loading" />; // will show in stage of blank screen
    //         result = 'Public';
    //       }
    //     } else if (data[draft_id].status === 'DELETED') {
    //       result = <AddDotLoader text="Canceling" />;
    //     } else if (data[draft_id].status === 'FINISHED') {
    //       fetchContent();
    //       changeFieldFromFirebase(draft_id, 'COMPLETED');
    //     } else {
    //       // result = <AddDotLoader text={data[draft_id].status} />;
    //       result = tabTitle === 2 ? (video_id ? 'Completed' : 'In Progress') : tabTitle === 0 ? status ? content_status : 'Processing' : content_status;
    //     }
    //   } else if (tabTitle !== 1) {
    //     if (tempUploadPercentages[draft_id] === 'Starting Upload' || tempUploadPercentages[draft_id] === 'Initializing' || tempUploadPercentages[draft_id] === 'Please wait' || tempUploadPercentages[draft_id] === 'Failed to Upload') {
    //       result = <AddDotLoader text={tempUploadPercentages[draft_id]} />;
    //     } else if (tempUploadPercentages[draft_id]) {
    //       result = <AddDotLoader text={`Uploading ${tempUploadPercentages[draft_id]}%`} />;
    //     } else {
    //       result = 'Failed to Upload';
    //     }
    //   } else {
    //     result = content_status;
    //   }
    // } else {
    //   result = tabTitle === 2 ? (video_id ? 'Completed' : 'In Progress') : tabTitle === 0 ? status ? content_status : 'Processing' : content_status;
    // }
    return result === 'Public' ? 'All Viewers' : result;
  };

  useEffect(() => {
    setHoldTheWeb(false);
  }, []);
  const [allSelectHint, setallSelectHint] = useState(false);
const [draft_idPLacer, setDraft_idPLacer] = useState(undefined);
const [isUnSelected, setisUnSelected] = useState(false);
const toggleItemSelection = (itemId, itemType, status) => {
  setSingleEdit(false);
  selectVideo();
  if (status !== 1) {
    setEditItemsStatus([]);
  }
  setisUnSelected(!isUnSelected);
  if (tabTitle === 1) {
      selectType('album');
  } else {
    selectType(undefined);
  }
  setSelectedItems((prevSelected) => {
    if (prevSelected.includes(itemId)) {
      return prevSelected.filter((id) => id !== itemId);
    }
    return [...prevSelected, itemId];
  });
  if (selectedItems.length === 1) {
    setallSelectHint(true);
  }
  if (itemType === 'draft_id') {
    setSelectedDraftIds((prevSelected) => {
      if (!prevSelected.includes(itemId)) {
        return [...prevSelected, itemId];
      }
      return prevSelected.filter((id) => id !== itemId); // Remove duplicate entry
    });
  } else if (itemType === 'content_id') {
    setSelectedContentIds((prevSelected) => {
      if (!prevSelected.includes(itemId)) {
        return [...prevSelected, itemId];
      }
      return prevSelected.filter((id) => id !== itemId); // Remove duplicate entry
    });
  }
};

useEffect(() => {
  if (selectedItems.length > 0) {
    if (content.length !== selectedItems.length) {
      setSelectAllChecked(false);
      // setisUnSelected(false);
    } else {
      setSelectAllChecked(true);
    }
  }
}, [isUnSelected]);

useEffect(() => {
  if (allSelectHint === true && selectedItems.length === 0) {
    setSelectedItems([]);
    setSelectedDraftIds([]);
    setSelectedContentIds([]);
    setallSelectHint(false);
  }
}, [allSelectHint]);

const deleteSelectedItems = () => {
  axios
    .post(`${baseUrl}/deleteDraftContent`, {
      token: getToken(),
      draft_id: selectedDraftIds,
      content_id: selectedContentIds,
    })
    .then((response) => {
      if (response?.data?.message !== true) {
        setAppSnackbar({
          isVisible: true,
          message: response?.data?.message,
        });
        fetchData();
      } else {
        // fetchContent();
        if (selectedDraftIds?.length > 0) {
          selectedDraftIds.map((dId) => {
            changeFieldFromFirebase(dId, 'DELETED');
            return null;
          });
        }
        // Handle success
        selectVideo(null);
        selectedDraftIds.map((item) => {
          updateDummy(item, 0);
          return null;
        });
        setEditItemsStatus([]);
        setEditItems([]);
        setSelectedItems([]);
        setSelectedDraftIds([]);
        setSelectedContentIds([]);
        setSelectAllChecked(false);
        setDataLoading(false);
        setIsContentLoaded(false);
        // fetchContent();
        getSubscription();
      }
    })
    .catch((error) => {
      setAppSnackbar({
        isVisible: true,
        message: error?.response?.data?.message,
      });
      setSelectedItems([]);
      setEditItemsStatus([]);
      selectedDraftIds.map((item) => {
        updateDummy(item, 0);
        return null;
      });
      setEditItems([]);
      setSelectedDraftIds([]);
      setSelectedContentIds([]);
      getSubscription();
      setDataLoading(false);
      // fetchContent();
    });
};

const [droper, setDroper] = useState(false);
const [videoFiles, setVideoFiles] = useState([]);
const [imageFiles, setImageFiles] = useState([]);
const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
const [popUpModalVisible, setPopUpModalVisible] = useState(false);

const revealUploadModal = () => {
  localStorage.setItem('opened_draft_id', null);
  autoSignIn().then((res) => {
    if (!res?.data?.subscription && res?.data?.totalContentUploaded >= ALLOWED_UPLOADS_COUNT) {
      setPopUpModalVisible(true);
      setEditLoader(false);
    } else {
      setUploadModalVisibility(true);
      setEditLoader(false);
    }
  });
};
const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleDrop = (e) => {
  // setEditLoader(true);
  e.preventDefault();
  const files = Array.from(e.dataTransfer.files);
  const typeVideoFiles = files.filter((file) => file.type.startsWith('video/'));
  const typeImageFiles = files.filter((file) => file.type.startsWith('image/'));
  setVideoFiles(typeVideoFiles);
  setImageFiles(typeImageFiles);
  setDroper(false);
  if (imageFiles.length > 0) {
    revealUploadModal();
  }
  if (videoFiles.length > 0) {
    handleOpen();
  }
};

const dragOver = (e) => {
  e.preventDefault();
  setDroper(true);
};
const dragLeave = (e) => {
  e.preventDefault();
  setDroper(false);
};

const hideUploadModal = () => {
  setUploadModalVisibility(false);
};
useEffect(() => {
if (open === true) {
  document.body.style.overflowY = 'hidden';
} else {
  document.body.style.overflowY = 'auto';
}
}, []);
const [albumIdGroup, setAlbumIdGroup] = useState([]);
const toggleSelectAll = () => {
  setSelectAllChecked(!selectAllChecked);
  if (!selectAllChecked) {
    const selectedDraftIds = [];
    const selectedContentIds = [];
    const allIds = content?.map((item) => {
      if (item.uploadIsTrying === 1) {
        // Omit items with uploadIsTrying === 1
        return null;
      }
      let idToUse;
      if (tabTitle === 2) {
        selectType(undefined);
        const idToPass = item?.content_id || item.draft_content_id;
        setEditItems((prevAlbumIdGroup) => {
          const uniqueSet = new Set([...prevAlbumIdGroup, idToPass]);
          const uniqueArray = Array.from(uniqueSet);
          return uniqueArray;
        });
        if (item?.draft_id?.startsWith('old_video')) {
          selectedContentIds.push(item.content_id || item.draft_content_id);
        } else {
          selectedDraftIds.push(item.draft_id);
        }
        idToUse = null; // No need to return id in this case
      } else if (tabTitle === 1) {
        selectType('album');
        idToUse = item?.content_id;
        setSelectedItems((prevAlbumIdGroup) => {
          if (!prevAlbumIdGroup.includes(idToUse)) {
            return [...prevAlbumIdGroup, idToUse];
          }
          return prevAlbumIdGroup;
        });
        // selectVideo(idToUse);
      } else if (tabTitle === 0) {
        selectType(undefined);
        const idToPass = item?.content_id;
        setEditItems((prevAlbumIdGroup) => {
          const uniqueSet = new Set([...prevAlbumIdGroup, idToPass]);
          const uniqueArray = Array.from(uniqueSet);
          return uniqueArray;
        });
        if (item?.draft_id?.startsWith('old_video')) {
          selectedContentIds.push(item.content_id);
          // selectedDraftIds.push(item.draft_id);
        } else {
          selectedDraftIds.push(item.draft_id);
        }
        idToUse = null; // No need to return id in this case
      }
      return idToUse;
    });
    setSelectedDraftIds(selectedDraftIds);
    setSelectedContentIds(selectedContentIds);
    if (tabTitle !== 1) {
      setSelectedItems(selectedDraftIds);
    }
  } else {
    makeEveryThingNull();
  }
};

useEffect(() => {
  setNeedToShowETA(true);
  setTopOrBottom(true);
  return () => {
    setTopOrBottom(false); // Set topOrBottom to false when leaving the component
    setSelectedItems([]);
    setSelectedDraftIds([]); // Clear the selected draft IDs
    setSelectedContentIds([]); // Clear the selected content IDs
    setSelectAllChecked(false);
  };
}, []);

  return (
    <Page className={styles.page} contentClassName={styles.pageContent} id="videos-page" headerTheme={styles.headerTheme}>
      {/* { droper && <DropMe dragLeave={dragLeave} dragOver={dragOver} handleDrop={handleDrop} />} */}
                    <UploadModal imageFiles={imageFiles} isVisible={uploadModalIsVisible} onHide={hideUploadModal} />
      <Tabs
        value={tabTitle}
        TabIndicatorProps={{ style: { background: '#02071A', height: '4px' } }}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        style={{ ...(getAccessChecker?.success === true && { marginTop: '31px' }) }}
      >
        <Tab label={<span className={tabTitle === 0 ? classes.activeTab : classes.customStyleOnTab}>{contentText?.videos || 'Videos'}</span>} />
        <Tab label={<span className={tabTitle === 1 ? classes.activeTab : classes.customStyleOnTab}>{contentText?.photoAlbums || 'Photo Albums'}</span>} />
        <Tab label={<span className={tabTitle === 2 ? classes.activeTab : classes.customStyleOnTab}>{contentText?.drafts || 'Drafts'}</span>} />
      </Tabs>
      {/* <Text.PageTitle className={styles.pageTitle}>{contentText?.title || 'Your Contents'}</Text.PageTitle> */}
      <div className={styles.filterBar}>
        <div className={styles.filterBarOrder}>Most Recent</div>
        <div
          className={styles.filterButton}
        >
          <FilterIcon className={styles.filterButtonIcon} />
        </div>
      </div>
    <div className={styles.dropdiv}>
    {/* {loading ? <RotatingLoader /> : ( */}
      <div className={styles.flexBorad}>
       <div className={styles.search_container}>
          <input
          value={searchTerm}
          onChange={handleSearchInputChange}
          type="text"
          className={styles.search_input}
          placeholder="Search by title"
          />
        {searchTerm !== '' && (
          <button type="submit" className={styles.search_button} onClick={performSearch}>
            <img src={Close} alt="Close" />
          </button>
        )}
       </div>
      <div className={styles.droperSelection}>
      <select
        className={styles.droperMain}
        id="dropdown"
        onChange={(e) => sortHandler(e.target.value)}
        value={sortBy} // Set the value attribute to dynamically update the selected option
      >
        <option value="date">Sorted by Date Published</option>
        <option value="name">Sorted by Name(A-Z)</option>
        {(tabTitle === 0 || tabTitle === 2) && (
          <option value="category">Sorted by Category</option>
        )}
        <option value="status">Sorted by Status</option>
      </select>
  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
  {removeSort === true && (
  <img
    src={Cross}
    className={styles.fiterRemover}
    onClick={() => { resetFilters(); handleSelect(''); }}
    alt=""
  />
)}

{/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
      </div>
      </div>

    </div>
      {editLoader === true ? <RotatingLoader />
      : <EditModal selectedItems={selectedItems} makeEveryThingNull={makeEveryThingNull} setSelectedItems={setSelectedItems} isVisible={openEditVideoModal} setContentDetails={setContentDetails} onHide={hideModal} contentDetails={contentDetails} uploadType={tabTitle === 0 ? ((selectedVideoContent?.length && selectedVideoContent[0].status) ? 'video' : 'draft') : tabTitle === 2 ? 'draft' : 'photo'} />}
        {dataLoader === true && <RotatingLoader />}
        {fetchingCategories && <RotatingLoader />}
      <div
        className={styles.videoListContainer}
        onDrop={handleDrop}
        onDragOver={(e) => dragOver(e)}
        onDragLeave={(e) => dragLeave(e)}
      >
        <Table id="menu-bar-table">
        {/* <hr className={styles.linexr} /> */}
          <TableHead className={styles.tableHeaderSticky}>
            {selectedVideo || selectedItems?.length > 0 ? (
              <>
                <TableRow className={styles.preferenceContainer}>
                  <TableHeaderCell className={styles.preferences} colSpan={7} scope="col">
                    <div className={styles.sticlyMe}>
                    <div className={styles.preferencesContent}>
                    <div className={styles.prefOptions}>
                    {selectedItems?.length <= 1 ? (
                      <div className={styles.editOptions}>
                        <div
                        className={styles.numVideosSelected}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (singleEdit === true) {
                            setEditVideoModal(true);
                          } else if (tabTitle === 1) {
                            fetchAlbumData(selectedItems[0]);
                          } else {
                            handleButtonClick(editItems[0], editItemsStatus[0]);
                          }
                        }}
                        >
                        {commonText?.edit || 'Edit'}
                        </div>
                      <div>
                      <PrefDropDown
                      name={contentText?.moreActions || 'More Actions'}
                      options={tabTitle ? [
                      {
                      name: deletingVideo
                      ? `${commonText?.deleting || 'Deleting'}...`
                      : commonText?.delete || 'Delete',
                      action: async () => { setDraft_idPLacer(undefined); setModal(true); },
                      }
                      ] : [
                      {
                      name: deletingVideo
                      ? `${commonText?.deleting || 'Deleting'}...`
                      : commonText?.delete || 'Delete',
                      action: async () => { setDraft_idPLacer(undefined); setModal(true); },
                      }
                      ]}
                      />
                      </div>
                      </div>
                    ) : selectedItems?.length > 1 ? (
                    <div
                      className={styles.numVideosSelected}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setModal(true)}
                    >
                    {commonText?.delete || 'Delete'}
                    {' '}
                    {selectedItems?.length}
                    {' '}
                    {tabTitle === 1 ? 'Album' : 'Videos'}
                    </div>
                    ) : null}
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  {isDownloading && (
    <>
      <div
        className={styles.numVideosSelected}
        style={{ cursor: 'pointer' }}
        onClick={() => { setEditVideoModal(true); }}
      >
        {commonText?.prepareFileDownload || 'Preparing file for download'}
      </div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <div className={styles.dotFlashing} />
    </>
  )}
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                      <div className={styles.preferencesContentButtons}>
                        {(editMenu || categoriesMenu) && (
                          <>
                            <label
                              className={styles.cancelEditMenu}
                              htmlFor="close-button-input"
                              style={{ cursor: 'pointer', color: '#5AA5EF' }}
                            >
                              {commonText?.cancel || 'Cancel'}
                            </label>
                            &nbsp;&nbsp;&nbsp;
                          </>
                        )}
                        {selectAllChecked
                        && (
                        <div style={{ marginRight: '10px' }} className={styles.numVideosSelected}>
                        {`items of page ${selectedPage} is selected`}
                        </div>
                        )}
                        <CancelIcon
                          className={styles.closeButton}
                          onClick={() => makeEveryThingNull()}
                        />
                        &nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                    </div>
                    <a
                      download={videoData?.title}
                      target="_blank"
                      id="video-download"
                      rel="noreferrer"
                      href={videoData?.file}
                      hidden
                    >
                      {commonText?.download || 'Download'}
                    </a>
                    {editMenu && (
                      <EditMenu
                        formField={editMenu[3]}
                        id={editMenu[0]}
                        name={editMenu[1]}
                        onChange={() => { onDataChange(); fetchData(); onMenuClose(); selectVideo(undefined); }}
                        onClose={onMenuClose}
                        setActionStatus={setActionHandlingStatus}
                        value={editMenu[2]}
                        type={selectedType}
                      />
                    )}
                    {visibilityMenu && (
                      <VisibilityMenu
                        category={visibilityMenu[5]}
                        subCategory={visibilityMenu[6]}
                        defaultGroup={visibilityMenu[4]}
                        id={visibilityMenu[0]}
                        onChange={() => { onDataChange(); fetchData(); onMenuClose(); selectVideo(undefined); }}
                        onClose={onMenuClose}
                        setActionStatus={setActionHandlingStatus}
                        value={visibilityMenu[2]}
                        setVisibilityMenu={setVisibilityMenu}
                        type={selectedType}
                      />
                    )}
                    {categoriesMenu && (
                      <CategoriesMenuContainer
                        defaultCategory={categoriesMenu[1]}
                        defaultSubCategory={categoriesMenu[2]}
                        id={categoriesMenu[0]}
                        onChange={() => { fetchData(); onMenuClose(); selectVideo(undefined); }}
                        onClose={onMenuClose}
                        setActionStatus={setActionHandlingStatus}
                        visibility={categoriesMenu[3]}
                      />
                    )}
                  </TableHeaderCell>
                </TableRow>
                <tr className={styles.preferenceContainerFill} />
              </>
            ) : null}
            <TableRow>
              {/* {tabTitle !== 1
              ? ( */}
              <TableHeaderCell
                align="center"
                className={cn({ [styles.headerCell]: true, [styles.checkboxCell]: true })}
              >
                {!isContentLoaded ? (
                  <Skeleton animation="wave" variant="circle" width={45} height={45} />
                ) : (
                  <Checkbox
                    status={selectAllChecked} // Use a state variable to manage the select all checkbox
                    onClick={toggleSelectAll} // Implement a function to toggle select all
                    setSelectAllChecked={setSelectAllChecked}
                  />
                )}
              </TableHeaderCell>
                    {/* ) : (
                      <TableHeaderCell
                align="center"
                className={cn({ [styles.headerCell]: true, [styles.checkboxCell]: true })}
                      />
                        )} */}
              <TableHeaderCell align="left" className={styles.headerCell}>
                <SubTitle
                  titleName={contentText?.preview || 'Content Preview'}
                  sortType="name"
                  isTitle
                />
              </TableHeaderCell>
              {
                tabTitle === 0 || tabTitle === 2 ? (
                  <TableHeaderCell align="center" className={styles.headerCell}>
                    <SubTitle
                      titleName={contentText?.categoryTitle || 'Category'}
                      sortType="category"
                    />
                  </TableHeaderCell>
                ) : null
              }
              {/* <TableHeaderCell align="center" className={styles.headerCell}>
                <SubTitle
                  titleName="Local status"
                  sortType="status"
                />
              </TableHeaderCell> */}
              <TableHeaderCell align="center" className={styles.headerCell}>
                <SubTitle
                  titleName={contentText?.status || 'Status'}
                  sortType="status"
                />
              </TableHeaderCell>
              <TableHeaderCell align="center" justifyContent="center" className={styles.headerCell}>
                {/* <div className={styles.theFlex}> */}
                <SubTitle
                  titleName={contentText?.datePublished || 'Date Published'}
                  sortType="date"
                />
                {/* {loading && <CircularProgress />}
                </div> */}
              </TableHeaderCell>
              <TableHeaderCell align="center" className={styles.headerCell}>
                {`${contentText?.size || 'Size'}`}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          {/* <hr className={styles.linex} /> */}
            <TableBody className={styles.tableBody}>
            {addDummyObject()}
            {isDummyLoader ? <DummyRow />
              : (dataLoading || !isContentLoaded)
                ? (
                  <>
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                    <DummyRow />
                  </>
                )
                : (content?.length === 0)
                ? <PreloadData>{tabTitle === 0 || tabTitle === 2 ? 'No Videos' : 'No Photos'}</PreloadData>
                : (
                  <>
                    {content?.length
                      ? content?.map(({
                        content: contentData, content_type, category_id, video_id, content_id, created, description, video_description, thumbnail, file, publish_date,
                        status, subcategory_id, draft_content_id, title, category_name, type, views, visibility, photos, thumbnails, content_status, category, album_size, video_size, draft_id, initializing, transcoder_status, workflow_status
                      }, idx, arr) => ((
                        type === 'album' ? (
                          <>
                            <TableRow
                              className={cn({ [styles.tableRow]: true, [styles.selectedRow]: (selectedType === 'album' && selectedVideo === content_id), })}
                              key={uuid()}
                            >
                              <TableCell className={styles.bodyCell}>
                                <Checkbox
                                  status={selectedVideo === content_id || selectedItems.includes(content_id)}
                                  //
                                  onClick={() => {
                                    toggleItemSelection(content_id, 'content_id');
                                    setEditItems((prevSelected) => {
                                      const idToUse = content_id;
                                      if (prevSelected.includes(idToUse)) {
                                        return prevSelected.filter((id) => id !== idToUse);
                                      }
                                      return [...prevSelected, idToUse];
                                    });
                                    setEditItemsStatus((prevSelectedStatus) => {
                                      const idToUse = status;
                                      if (prevSelectedStatus.includes(idToUse)) {
                                        return prevSelectedStatus.filter((id) => id !== idToUse);
                                      }
                                      return [...prevSelectedStatus, idToUse];
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                className={cn({ [styles.bodyCell]: true, [styles.videoPreviewCell]: true })}
                              >
                                <div className={styles.previewContainer}>
                                  {/* eslint-disable-next-line */}
                                  <img
                                    onClick={() => { toggleVideoSelection(content_id, arr[idx], 'album', true); }}
                                    className={cn({ [styles.previewImage]: true, [styles.albumImage]: true })}
                                    src={thumbnail}
                                    alt={content_id}
                                  />
                                  <div className={styles.previewText}>
                                    {/* eslint-disable-next-line */}
                                    <span className={styles.previewTitle}>
                                    {/* <span onClick={() => { toggleVideoSelection(content_id, arr[idx], 'album', true); }} className={styles.previewTitle}> */}
                                      {title}
                                    </span>
                                    {
                                      showImage === content_id
                                        ? (
                                          <DownIcon
                                            className={styles.expandIcon}
                                            onClick={() => { setCallAlbum([]); setShowImage(null); setAlbumImages(undefined); }}
                                          />
                                        ) : (
                                          <UpIcon
                                            className={styles.expandIcon}
                                            onClick={() => { showAlbumImages(content_id); setShowImage(content_id); setAlbumImages(photos); }}
                                          />
                                        )
                                    }
                                    {/* eslint-disable-next-line */}
                                    <span className={styles.previewDescription}>{description}</span>
                                    {/* <span onClick={() => { toggleVideoSelection(content_id, arr[idx], 'album', true); }} className={styles.previewDescription}>{description}</span> */}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell align="center" className={styles.bodyCell}>
                              {tempUploadPercentages[content_id] ? tempUploadPercentages[content_id] : content_status === 'Public' ? 'All Viewers' : content_status }
                              </TableCell>
                              <TableCell align="center" className={styles.bodyCell}>
                                {changeDateFormat(publish_date?.split(' ')[0])}
                              </TableCell>
                              <TableCell align="center" className={styles.bodyCell}>
                                {tempUploadSizes[content_id] ? tempUploadSizes[content_id] : (album_size < 1 && album_size > 0 ? `${(album_size * 1024).toFixed(2)} KB` : album_size < 1000 && album_size > 1 ? `${album_size} MB` : album_size > 1000 ? `${(album_size / 1024).toFixed(2)} GB` : '--')}
                              </TableCell>
                            </TableRow>
                            {
                              showImage === content_id ? (
                                <TableRow
                                  className={cn({ [styles.tableRow]: true, [styles.selectedRow]: (selectedType === 'album' && selectedVideo === content_id), })}
                                  key={uuid()}
                                >
                                  <TableCell align="center" className={styles.bodyCell} colSpan={7}>
                                    <PhotoList photos={callAlbum[0]?.photos} albumId={content_id} />
                                  </TableCell>
                                </TableRow>
                              ) : null
                            }
                          </>
                        ) : filter && status ? (
                    <TableRow
                    className={cn({
                    [styles.tableRow]: true,
                    [styles.processingVideo]: tabTitle === 0 && !status,
                    [styles.selectedRow]:
                    selectedType === 'video'
                    && selectedVideo === (draft_content_id || content_id || video_id),
                    })}
                    key={uuid()}
                    >

                  <TableCell className={styles.bodyCell}>
                  {draft_content_id || content_id || video_id ? (
                  <Checkbox
                status={
                  selectedVideo !== undefined
                  && (selectedVideo === (tabTitle === 2 ? draft_content_id : content_id || video_id))
                }
                onClick={() => {
                  if (draft_id && draft_id?.slice(0, 9) !== 'old_video') {
                    toggleItemSelection(draft_id, 'draft_id', status);
                  } else {
                    toggleItemSelection(draft_id || draft_content_id, 'draft_id', status);
                  }
                  setEditItems((prevSelected) => {
                    const idToUse = tabTitle === 0 ? content_id : tabTitle === 2 && draft_content_id;
                    if (prevSelected.includes(idToUse)) {
                      return prevSelected.filter((id) => id !== idToUse);
                    }
                    return [...prevSelected, idToUse];
                  });
                  setEditItemsStatus((prevSelectedStatus) => {
                    const idToUse = status;
                    if (prevSelectedStatus.includes(idToUse)) {
                      return prevSelectedStatus.filter((id) => id !== idToUse);
                    }
                    return [...prevSelectedStatus, idToUse];
                  });
                }}
                  selected={selectedItems.includes(
                    draft_id && draft_id?.slice(0, 9) !== 'old_video' ? draft_id : content_id || draft_content_id
                  )}
                  />
                  ) : <Spinner />}
                  </TableCell>
                            <TableCell
                              align="left"
                              className={cn({ [styles.bodyCell]: true, [styles.videoPreviewCell]: true })}
                            >
                              <div onClick={() => { setSelectedIdType((video_id || content_id) ? 'videoId' : 'draft'); }} className={styles.previewContainer}>
                              {/* <div onClick={() => { (content_id || video_id || draft_content_id) && toggleVideoSelection(tabTitle === 2 ? draft_content_id : (content_id || video_id), arr[idx], 'video', true); setSelectedIdType((video_id || content_id) ? 'videoId' : 'draft'); }} className={styles.previewContainer}> */}
                                <img
                                  className={styles.previewImage}
                                  src={Array.isArray(thumbnail) ? thumbnail[0] || AlterImage : (thumbnail || thumbnails) || AlterImage}
                                  alt={title}
                                />
                                <div className={styles.previewText}>
                                  <span className={styles.previewTitle}>{title}</span>
                                  <span className={styles.previewDescription}>{description || video_description}</span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="center" className={styles.bodyCell}>
                              {tabTitle === 2 ? category_name : category}
                            </TableCell>
                            {/* <TableCell align="center" className={styles.bodyCell}>
                              {dataD[draft_id]?.status === 'Finished' ? (
                                <a href={dataD[draft_id]?.key}>M3U8</a>
                              ) : (
                                dataD[draft_id]?.status
                              )}
                            </TableCell> */}
                            <TableCell align="center" className={styles.bodyCell}>
                            {renderContent(draft_id, content_status, status, video_id)}
                            {tabTitle !== 1 && (uploadPercentages[draft_id] > 0 || uploadPercentages[draft_id] === 'Starting Upload') && (draft_content_id || content_id || video_id) && (
                              <button onClick={() => { setModal(true); setDraft_idPLacer(draft_id); }} className={styles.cancelPlz}>
                                Cancel
                              </button>
                            )}
                            {/* {!Number.isNaN(eta) && tabTitle !== 1 && uploadPercentages[draft_id] > 0 && (
                                <div className={styles.Sppped}>
                                  {eta > 1 ? `${eta} Minutes remaining` : eta <= 1 ? 'Less than a minute' : 'Calculating'}
                                </div>
                                  )} */}
                            </TableCell>
                            <TableCell align="center" className={styles.bodyCell}>{changeDateFormat(publish_date?.split(' ')[0]) || created?.split(' ')[0]}</TableCell>
                            <TableCell align="center" className={styles.bodyCell}>
                              {(video_size < 1 && video_size > 0 ? `${(video_size * 1024).toFixed(2)} KB` : video_size < 1000 && video_size > 1 ? `${video_size} MB` : video_size > 1000 ? `${(video_size / 1024).toFixed(2)} GB` : '--')}
                            </TableCell>
                    </TableRow>
                        ) : !filter ? (
                          <TableRow
                          className={cn({
                          [styles.tableRow]: true,
                          [styles.processingVideo]: tabTitle === 0 && !status,
                          [styles.selectedRow]:
                          selectedType === 'video'
                          && selectedVideo === (draft_content_id || content_id || video_id),
                          })}
                          key={uuid()}
                          >
                        <TableCell className={styles.bodyCell}>
                        {draft_content_id || content_id || video_id ? (
                        <Checkbox
                      status={
                        selectedVideo !== undefined
                        && (selectedVideo === (tabTitle === 2 ? draft_content_id : content_id || video_id))
                      }
                      onClick={() => {
                        if (draft_id && draft_id?.slice(0, 9) !== 'old_video') {
                          toggleItemSelection(draft_id, 'draft_id', status);
                        } else {
                          toggleItemSelection(draft_id || draft_content_id, 'draft_id', status);
                        }
                        setEditItems((prevSelected) => {
                          const idToUse = tabTitle === 0 ? content_id : tabTitle === 2 && draft_content_id;
                          if (prevSelected.includes(idToUse)) {
                            return prevSelected.filter((id) => id !== idToUse);
                          }
                          return [...prevSelected, idToUse];
                        });
                        setEditItemsStatus((prevSelectedStatus) => {
                          const idToUse = status;
                          if (prevSelectedStatus.includes(idToUse)) {
                            return prevSelectedStatus.filter((id) => id !== idToUse);
                          }
                          return [...prevSelectedStatus, idToUse];
                        });
                      }}
                        selected={selectedItems.includes(
                          draft_id && draft_id?.slice(0, 9) !== 'old_video' ? draft_id : draft_id || content_id || draft_content_id
                        )}
                        />
                        ) : <Spinner />}
                        </TableCell>
                                  <TableCell
                                    align="left"
                                    className={cn({ [styles.bodyCell]: true, [styles.videoPreviewCell]: true })}
                                  >
                                    <div onClick={() => { setSelectedIdType((video_id || content_id) ? 'videoId' : 'draft'); }} className={styles.previewContainer}>
                                    {/* <div onClick={() => { (content_id || video_id || draft_content_id) && toggleVideoSelection(tabTitle === 2 ? draft_content_id : (content_id || video_id), arr[idx], 'video', true); setSelectedIdType((video_id || content_id) ? 'videoId' : 'draft'); }} className={styles.previewContainer}> */}
                                    <img
                                        className={styles.previewImage}
                                        src={Array.isArray(thumbnail) ? thumbnail[0] || AlterImage : (thumbnail || thumbnails) || AlterImage}
                                        // eslint-disable-next-line
                                        onError={(event) => event.target.src = AlterImage}
                                        alt={title}
                                    />
                                      <div className={styles.previewText}>
                                        <span className={styles.previewTitle}>{title}</span>
                                        <span className={styles.previewDescription}>{description || video_description}</span>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center" className={styles.bodyCell}>
                                    {tabTitle === 2 ? (category_name || category) : category}
                                  </TableCell>
                                  {/* <TableCell align="center" className={styles.bodyCell}>
                                    {dataD[draft_id]?.status === 'Finished' ? (
                                      <a href={dataD[draft_id]?.key}>M3U8</a>
                                    ) : (
                                      dataD[draft_id]?.status
                                    )}
                                  </TableCell> */}
                                  <TableCell align="center" className={styles.bodyCell}>
                                  {/* {renderContent(draft_id, content_status, status, video_id) === '' ? 'Publishing video' : transcoder_status != null && transcoder_status !== 'Finished' ? <AddDotLoader text="Processing" /> : typeof uploadPercentages[draft_id] === 'number' ? <AddDotLoader text={`Uploading ${tempUploadPercentages[draft_id]}%`} /> : content_status === 'Public' ? 'All Viewers' : renderContent(draft_id, content_status, status, video_id)} */}
                                  {renderContent(draft_id, content_status, status, video_id, transcoder_status, workflow_status)}
                                  {tabTitle !== 1 && (uploadPercentages[draft_id] > 0 || uploadPercentages[draft_id] === 'Starting Upload') && (draft_content_id || content_id || video_id) && (
                                    <button onClick={() => { setModal(true); setDraft_idPLacer(draft_id); }} className={styles.cancelPlz}>
                                      Cancel
                                    </button>
                                  )}
                                  {/* {!Number.isNaN(eta) && tabTitle !== 1 && uploadPercentages[draft_id] > 0 && (
                                      <div className={styles.Sppped}>
                                        {eta > 1 ? `${eta} Minutes remaining` : eta <= 1 ? 'Less than a minute' : 'Calculating'}
                                      </div>
                                        )} */}
                                  </TableCell>
                                  <TableCell align="center" className={styles.bodyCell}>{changeDateFormat(publish_date?.split(' ')[0]) || created?.split(' ')[0]}</TableCell>
                                  <TableCell align="center" className={styles.bodyCell}>
                                    {(video_size < 1 && video_size > 0 ? `${(video_size * 1024).toFixed(2)} KB` : video_size < 1000 && video_size > 1 ? `${video_size} MB` : video_size > 1000 ? `${(video_size / 1024).toFixed(2)} GB` : '--')}
                                  </TableCell>
                          </TableRow>
                              ) : null
                      )))
                      : <PreloadData>No content uploaded</PreloadData>}
                  </>
                )}
            </TableBody>
        </Table>
      </div>
      {
        selectedPage && !selectedVideo ? (
          <ReactPaginate
            nextLabel={`${window.screen.width > 900 ? 'next >' : '>'}`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel={`${window.screen.width > 900 ? '< previous' : '<'}`}
            pageClassName={styles.pageItem}
            pageLinkClassName={styles.pageLink}
            previousClassName={styles.pageItem}
            previousLinkClassName={styles.pageLink}
            nextClassName={styles.pageItem}
            nextLinkClassName={styles.pageLink}
            breakLabel="..."
            breakClassName={styles.pageItem}
            breakLinkClassName={styles.pageLink}
            containerClassName={styles.pagination}
            activeClassName={styles.active}
            renderOnZeroPageCount={null}
            forcePage={selectedPage - 1}
          />
        ) : null
      }

      {
        isOpenModal && (
          <InfoModal
            isVisible={isOpenModal}
            title={draft_idPLacer ? 'Are you sure you want to cancel ?' : 'Are you sure you want to delete?'}
            onHide={() => { setModal(false); }}
            continueButton={draft_idPLacer ? 'Exit' : 'Go back'}
            isLoading={dataLoading}
            // content="You are now deleting, all videos and photos will now be a draft until you
            // Add them to a new category. Are you sure you want to continue?"
            action={() => {
              setModal(false);
              setSelectedItems([]);
              if (draft_idPLacer) {
                handleEncouter(draft_idPLacer);
              } else if (tabTitle === 1) {
                deleteVideo();
                setDataLoading(true);
              } else {
                deleteSelectedItems();
                setDataLoading(true);
              }
            }}
            actionButton={draft_idPLacer ? 'Continue to cancel' : 'Delete'}
            isDelete
          />
        )
      }
      <VideoPopupModal
        isVisible={isOpenPopupModal}
        onHide={() => { setOpenPopupModal(false); }}
        showVideo={modalShowPopUp === '1'}
      />
    </Page>
  );
};
export default YourVideos;
