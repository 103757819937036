/* eslint-disable radix */
/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import cn from 'classnames';
import { AppContext, AuthContext } from 'Context';
import { Button, Grid, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { EmailEditModal, PhoneNumberEditModal, PasswordEditModal } from 'Components/edits';
// import PageLayout from 'Pages/public/landing/plans/CheckoutForm/components/page-layout';
import { Page, Header } from 'Components';
import { PLANS } from 'Commons';
import styles from './index.module.css';
import { DataView } from '../components/data-view';
import { SettingsPage } from '../components/page';

export const BillingSettings = () => {
  const { userDetails, allPlans, subscriptionDetails, setAppSnackbar, getSubscription, langText, isTrail } = useContext(AppContext);
  const { isInActiveUser, gettingSubscription } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const billPayment = langText?.settings?.billPayment;
  const commonText = langText?.common;
  const accountText = langText?.settings?.account;
  const cancellationText = billPayment?.cancellation;
  const [emailEditModalIsVisible, setEmailEditModalVisibility] = useState(false);
  const [phoneNumberEditModalIsVisible, setPhoneNumberEditModalVisibility] = useState(false);
  const [passwordEditModalIsVisible, setPasswordEditModalVisibility] = useState(false);
  const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };
  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const revealEmailEditModal = () => { setEmailEditModalVisibility(true); };
  const hideEmailEditModal = () => { setEmailEditModalVisibility(false); };
  const revealPhoneNumberEditModal = () => { setPhoneNumberEditModalVisibility(true); };
  const hidePhoneNumberEditModal = () => { setPhoneNumberEditModalVisibility(false); };
  const revealPasswordEditModal = () => { setPasswordEditModalVisibility(true); };
  const hidePasswordEditModal = () => { setPasswordEditModalVisibility(false); };
  const [cardDetails, setCardDetails] = useState({ barnd: undefined, last4: undefined });
  const planName = subscriptionDetails?.subscription?.product_name;
  const planOption = subscriptionDetails?.action;
  const subscription_id = subscriptionDetails?.subscription?.id;
  const status = subscriptionDetails?.has_activePlan;
  const purchaseId = subscriptionDetails?.subscription?.purchase_plan_id;
  const freePlan = parseInt(subscriptionDetails?.subscription?.plan_type, 10) === 3;
  const paymentProcessing = parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 4 && subscriptionDetails?.has_activePlan;
  const paymentRetry = parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 2 && subscriptionDetails?.has_activePlan;
  useEffect(() => {
    const paymentMethods = subscriptionDetails?.paymentMethods;
    const defaultPaymentMethod = subscriptionDetails?.default_payment_methods;
    if (paymentMethods && defaultPaymentMethod) {
      const paymentMethod = paymentMethods.find(({ id }) => id === defaultPaymentMethod);
      const brand = paymentMethod?.card?.brand;
      const last4 = paymentMethod?.card?.last4;
      const lastAttempt = paymentMethod?.last_attempt;
      setCardDetails({ brand, last4, lastAttempt });
    }
  }, [subscriptionDetails]);
  const [done, setDone] = useState(true);
  const handleCancellation = () => {
    navigate(Pathname.cancelSubscription);
  };
  const [openCencellationModal, setOpenCencellationModal] = React.useState(location.search?.includes(Pathname.cancelSubscription));
  useEffect(() => {
    if (location.search?.includes(Pathname.cancelSubscription)) {
      setOpenCencellationModal(true);
      // alert('Pathname.cancelSubscription');
    } else {
      setOpenCencellationModal(false);
    }
  }, [location]);
  const endDateOfSub = subscriptionDetails?.end_date ? new Date(subscriptionDetails.end_date) : null;

  let formattedDate = null;
  if (endDateOfSub && !Number.isNaN(endDateOfSub)) {
    const day = endDateOfSub.getDate();
    const month = endDateOfSub.toLocaleString('en-US', { month: 'short' });
    const year = endDateOfSub.getFullYear();

    formattedDate = `${day} ${month} ${year}`;
  }
  const { isValidating: cancellingSubscription, mutate: cancelSubscription } = useSWR([endpoints.cancelSubscription, { plan_id: purchaseId }], {
    fetcher: (url, purchaseId) => dataFetcher(url, purchaseId),
    onSuccess: ({ success, data }) => {
      if (success) {
        getSubscription();
        setDone(false);
        setAppSnackbar({
          isVisible: true,
          type: 'success',
          message: 'Membership removed',
        });
        setTimeout(() => {
          navigate(Pathname.dashboard);
        }, 3000);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while cancelling',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: paymentRetrying, mutate: retryPayment } = useSWR([
    endpoints.retryPayment, purchaseId], {
    fetcher: (url, subscription_id) => dataFetcher(url, { subscription_id }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setAppSnackbar({
          isVisible: true,
          type: 'success',
          message,
        });
        setTimeout(() => {
          window.location.replace(Pathname.dashboard);
        }, 3000);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message,
        });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: (responseData || 'Oops! Something went wrong.') });
    },
  });

  const [isDisabled, setIsDisabled] = useState({ isDisabled: true, error: 'You are not allowed to cancel subscription at this time' });

  const [trial_end, setTrial_end] = useState({ year: undefined, month: undefined, day: undefined });
  const [today, setToday] = useState({ year: undefined, month: undefined, day: undefined });
  useEffect(() => {
    if (subscriptionDetails?.subscription?.end_date) {
      const date = subscriptionDetails?.subscription?.end_date?.split(' ')[0].split('-');
      const time = subscriptionDetails?.subscription?.end_date?.split(' ')[1].split(':');
      const d = new Date();
      const year = parseInt(date[0]);
      const month = parseInt(date[1]);
      const day = parseInt(date[2]);
      const todaysDate = { year: d.getFullYear(), month: d.getMonth(), day: d.getDate(), time: d.getTime() };
      setTrial_end({ year, month, day, time });
      setToday(todaysDate);
      if (todaysDate.year > year) {
        setIsDisabled({ ...isDisabled, isDisabled: false });
      } else if (todaysDate.month > month) {
        setIsDisabled({ ...isDisabled, isDisabled: false });
      } else if (todaysDate.month === month && todaysDate.day > day) {
        setIsDisabled({ ...isDisabled, isDisabled: false });
      } else {
        setIsDisabled({ ...isDisabled, isDisabled: true });
      }
    }
  }, [subscriptionDetails]);
  useEffect(() => {
  }, [today, trial_end]);

  const handleCancelSubscription = () => {
    // if (!isDisabled.isDisabled) {
    //   cancelSubscription();
    // } else {
    //   setAppSnackbar({ isVisible: true, type: 'error', message: isDisabled.error });
    // }
    cancelSubscription();
  };
  const endDate = new Date(subscriptionDetails?.subscription?.end_date);
  const currentDate = new Date();
  const differenceInMs = endDate - currentDate;
  const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
  const Dialouge = () => (
    <>

      <Dialog
        fullScreen
        open={openCencellationModal}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {' '}
        {/* <Page
          // className={styles.page}
          // containerClassName={styles.pageContainer}
          // contentClassName={styles.pageContent}
          noBottomBar
          noDefaultBg
          noFixedSidebar
          transparentCompactHeader
        > */}
        <Header
          transparentCompactHeader
          headerTheme
        />
        <div className={styles.theMain}>
          <div className={styles.mainBox}>
            <div className={styles.main_top}>
              <h2 className={styles.main_heading}>
                {cancellationText?.title || 'Are you done Sharing?'}
              </h2>
              <div className={styles.main_subHeading}>
                {cancellationText?.description || 'If you are sure you’d like to cancel your subscription, finish below.'}
                {' '}

              </div>
            </div>
            <div className={styles.main_box}>
              <div className={styles.main_box_list}>
                <ul>
                {
                  subscriptionDetails?.subscription?.is_trial === '0'
                  && (
                    <li>
                      {cancellationText?.pointOne || 'Cancellation will be effective at the end of your current billing period on'}
                      {' '}
                      {`${trial_end.day}/${trial_end.month}/${trial_end.year}`}
                    </li>
                  )
                }
                  <li>{cancellationText?.pointTwo || 'Come back anytime. If you restart within 30 days your profiles, videos, viewers and account details will be waiting for you.'}</li>
                </ul>
              </div>
              <div className={styles.main_box_buttons}>
                <button
                  disabled={cancellingSubscription || !done}
                  className={styles.firstButton}
                  onClick={() => handleCancelSubscription()}
                >
                  {(cancellingSubscription) ? !done ? 'Cancelled' : 'Cancelling' : (cancellationText?.finish || 'Finish Cancellation')}

                </button>

                <button className={styles.secondButton} disabled={cancellingSubscription || !done} onClick={() => navigate(-1)}>{commonText?.goBack || 'Go Back'}</button>
              </div>
            </div>
          </div>
        </div>
        {/* </Page> */}
      </Dialog>
    </>
  );
  return (
    <>
      <SettingsPage
        title={billPayment?.title || 'Billing and Payment'}
        description={
          billPayment?.description
          || 'Edit Payment method, add and remove cards'
        }
      >
        <DataView
          data={[
            {
              labelTitle: (
                <div className={styles.main}>
                  <div className={styles.main_heading}>
                    {billPayment?.membershipBilling || 'Membership & Billing'}
                  </div>
                  {subscription_id
                    && subscription_id !== undefined
                    && subscriptionDetails?.has_activePlan
                    && !freePlan
                    && !paymentProcessing && (
                      <button
                        onClick={() => (
                          // cardDetails?.lastAttempt?.payment_status === '0' ? setAppSnackbar({
                          //   isVisible: true,
                          //   type: 'error',
                          //   message: cardDetails?.lastAttempt?.failure_reason }):
                          paymentRetry ? retryPayment() : handleCancellation())}
                        className={cn({
                          [styles.main_button]: true,
                          [styles.reActiveButton]: paymentRetry,
                          [styles.disabled]: paymentRetrying || subscriptionDetails?.subscription?.is_cancelled === '1'
                        })}
                        disabled={paymentRetrying || subscriptionDetails?.subscription?.is_cancelled === '1'}
                      >
                        {paymentRetry
                          ? billPayment?.reActivate || 'Reactivate'
                          : billPayment?.cancelMembership
                          || 'Cancel Membership'}
                      </button>
                    )}
              {subscriptionDetails?.subscription?.is_trial === '1' && (
                <p className={styles.remaing}>
                  Trial ends in
                  {' '}
                {differenceInDays}
                {' '}
                {differenceInDays > 1 ? 'days' : 'day'}
                </p>
              )}
                </div>
              ),
              data: (
                <>
                  <div className={styles.labelLinks_container_main}>
                    <div className={styles.labelLinks_container_sub}>
                      <div
                        className={`${styles.labelLinks} ${styles.firstLabelLink} `}
                      >
                        <div
                          className={`${styles.label} ${styles.firstLabel} `}
                        >
                          {userDetails?.email}
                        </div>
                        {!isInActiveUser && !gettingSubscription ? (
                          <Grid
                            onClick={revealEmailEditModal}
                            className={`${styles.link} ${styles.firstLink} `}
                          >
                            {billPayment?.changeEmail || 'Change account email'}
                          </Grid>
                        ) : null}
                      </div>
                      {!isInActiveUser && !gettingSubscription ? (
                        <div
                          className={`${styles.labelLinks} ${styles.secondLabelLink} `}
                        >
                          <div
                            className={`${styles.label} ${styles.secondLabel} `}
                          >
                            Password ********
                          </div>
                          <Grid
                            onClick={revealPasswordEditModal}
                            className={`${styles.link} ${styles.secondLink} `}
                          >
                            {billPayment?.changePassword || 'Change Password'}
                          </Grid>
                        </div>
                      ) : null}
                      <div
                        className={`${styles.labelLinks} ${styles.secondLabelLink} `}
                      >
                        <div
                          className={`${styles.label} ${styles.secondLabel} `}
                        >
                          {' '}
                          {userDetails?.mobile}
                        </div>
                        {!isInActiveUser && !gettingSubscription ? (
                          <Grid
                            onClick={revealPhoneNumberEditModal}
                            className={`${styles.link} ${styles.firstLink} `}
                          >
                            {userDetails?.mobile
                              ? 'Change phone number'
                              : billPayment?.addPhonenumber
                              || 'Add phone number'}
                          </Grid>
                        ) : null}
                      </div>
                    </div>
                    <div className={styles.labelLinks_container_sub}>
                      <div
                        className={`${styles.labelLinks} ${styles.secondLabelLink} `}
                      >
                        <div
                          className={`${styles.label} ${styles.secondLabel} `}
                        >
                          {cardDetails.brand}
                          {cardDetails.last4 && (
                            <>
                              <span className={`${styles.colorBlack} `}>
                                {' '}
                                ***** ****
                                {' '}
                                {cardDetails.last4}
                                {' '}
                              </span>
                              {isInActiveUser && cardDetails?.lastAttempt?.payment_status === '0' ? (
                                <>
                                  <span className={styles.redColor}>
                                    {'Update payment method' || billPayment?.updatePaymentMethod}
                                  </span>
                                  <div className={styles.redColorExpired}>
                                    {`(${cardDetails?.lastAttempt?.failure_reason || 'Expired'
                                      })`}

                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                        </div>
                        <Grid
                          component={Link}
                          to={Pathname.getSettingsPath(
                            Pathname.settings.managePaymentMethods
                          )}
                          className={`${styles.link} ${styles.secondLink} `}
                        >
                          {billPayment?.manageInfo || 'Manage Payment Info'}
                        </Grid>
                      </div>
                    </div>
                    <p className={styles.expired}>
                      {subscriptionDetails?.expired ? 'Your plan has expired, please upgrade' : null}
                    </p>
                  </div>
                </>
              ),
            },
          ]}
        />
        <DataView
          data={[
            {
              labelTitle: (
                <div className={styles.main}>
                  <div className={styles.main_heading}>
                    {billPayment?.planDetails || 'Plan Details'}
                  </div>
                </div>
              ),
              // labelHasAddIcon: true,
              // onLabelClick: () => { alert('click on card to make payment '); },
              data: (
                <>
                  <div
                    style={{ borderTop: 'solid' }}
                    className={styles.labelLinks_container_main}
                  >
                    <div className={styles.labelLinks_container_sub}>
                      <div
                        className={`${styles.labelLinks} ${styles.firstLabelLink} `}
                      >
                        <div
                          className={`${styles.label} ${styles.firstLabel} `}
                        >
                          { !subscriptionDetails?.expired ? userDetails?.isSubscription?.plan || 'Free Plan' : 'Plan Expired'}
                          <Tooltip title={userDetails?.isSubscription && !subscriptionDetails?.expired
                            ? 'Unlimited uploads and Unlimited storage'
                            : 'Upgrade to premium to enjoy Unlimited uploads and Unlimited Storage'}
                          >
                            <InfoIcon size="large" />
                          </Tooltip>
                        </div>
                        <Grid
                          component={Link}
                          to={userDetails?.isSubscription && !subscriptionDetails?.expired ? '' : Pathname.plans}
                          className={`${styles.link} ${styles.firstLink} ${userDetails?.isSubscription && !subscriptionDetails?.expired && styles.linkDisabled}`}
                        >
                          {userDetails?.isSubscription && !subscriptionDetails?.expired ? 'Upgraded to ' : 'Upgrade to '}
                          {`${allPlans[0]?.product_name} plan`}
                        </Grid>
                      </div>
<div className={styles.leftico}>
{
  formattedDate !== null
    ? (!subscriptionDetails?.expired ? `Expires on ${formattedDate}` : `Expired on ${formattedDate}`)
    : null
}
</div>
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </SettingsPage>
      <EmailEditModal
        isVisible={emailEditModalIsVisible}
        onHide={hideEmailEditModal}
        title={
          accountText?.updateEmail ? accountText?.updateEmail : 'Update Email'
        }
        resetCodeLabel={
          accountText?.enterResetCode
            ? accountText?.enterResetCode
            : 'Enter reset code'
        }
        newEmailLabel={
          accountText?.enterNewEmail
            ? accountText?.enterNewEmail
            : 'Enter New Email'
        }
      />
      <PhoneNumberEditModal
        isVisible={phoneNumberEditModalIsVisible}
        onHide={hidePhoneNumberEditModal}
        title={
          accountText?.updatepPhoneNumber
            ? accountText?.updatepPhoneNumber
            : 'Update Phone number'
        }
        phoneNumerLabel={
          accountText?.enterPhoneNumber
            ? accountText?.enterPhoneNumber
            : 'Enter Phone number'
        }
      />
      <PasswordEditModal
        isVisible={passwordEditModalIsVisible}
        onHide={hidePasswordEditModal}
        title={
          accountText?.updatePassword
            ? accountText?.updatePassword
            : 'Update Password'
        }
        currentPasswordLabel={
          accountText?.enterCurrentPassword || 'Enter Current Password'
        }
        newPasswordLabel={accountText?.enterNewPassword || 'Enter new password'}
        passwordRule={
          accountText?.passwordDescription
          || 'Use a minimum of 6 characters (case sensitive) with at lease one number and one special character.'
        }
      />

      {openCencellationModal && Dialouge()}
    </>
  );
};
