/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
import React, { useContext, useState, useEffect, memo } from 'react';
import { AppContext, AuthContext } from 'Context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Spinner from 'Components/Spinner';
import { VideoPopupModal } from 'Components/VideoPopup';
import ProgressBar from 'Components/header/components/ProgressBar';
import useSWR from 'swr';
import moment from 'moment';
import { dataFetcher, endpoints } from 'Api';
import { Collapse, Fade, Zoom, Grid, Tooltip, CircularProgress, Box } from '@material-ui/core';
import { Button, Page, Snackbar, Text, ProjectorShimmer } from 'Components';
import { ref, off, onValue } from 'firebase/database';
import { Pathname } from 'Routes';
import { ReactComponent as UploadIcon } from 'Assets/video-upload.svg';
import { ReactComponent as ImageUploadIcon } from 'Assets/image-upload.svg';
import { ReactComponent as VerticalLine } from 'Assets/vertical-line.svg';
import { ALLOWED_UPLOADS_COUNT, PLANS } from 'Commons';
import { InfoModal } from './info-modal';
import styles from './index.module.css';
import UserRequest from './UserRequest';

const transitionTimeout = 300;

const SectionTitle = ({ title, action: Action }) => (
  <div className={styles.sectionTitle}>
    <Text.SectionTitle>{title}</Text.SectionTitle>
    {Action && <Action />}
  </div>
);

export const Dashboard = () => {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const location = useLocation();
  const navigate = useNavigate();
  // const [availableStorage, setAvailableStorage] = useState(null);
  const { userDetails, firebaseDatabase, subscriptionDetails, langText, setViewerListUpdate, isViewerListUpdated, getSubscription, setTopOrBottom, autoSignIn, setHelpNeed, setNeedToShowETA, uploaderLoader, setUploaderLoader } = useContext(AppContext);
  const { isInActiveUser, gettingSubscription } = useContext(AuthContext);
  const contentDashboard = langText?.contentDashboard;
  const commonText = langText?.common;
  const [remainingDays, setRemainingDays] = useState(null);
  const [popUpModalVisible, setPopUpModalVisible] = useState(false);
  const [storageDetails, setStorageDetails] = useState({
    max: 0,
    used: 0,
    available: 0,
    availablePercentage: 100,
    usedPercentage: 0,
    video_disk_used_mb: 0,
    allowedViewers: '',
    viewer: 0,
    planName: '',
    upgradePlanName: '',
    upgradePlanViewers: '',
    upgradePlanStorage: '',
    totalContentUploaded: 0,
    totalPhotoUploaded: 0,
    totalVideoUploaded: 0
  });
  const [isStorageFull, setStorageFull] = useState(false);
  const isStorageFullCondition = parseInt(subscriptionDetails?.storageUsed?.used_storage, 10) === parseInt(subscriptionDetails?.storageUsed?.total_storage, 10);
  // const checkSize = (size) => {
  //   let value;
  //   if (size >= 1000000) {
  //     value = (Math.floor(size / 10000) / 100);
  //     return `${value} TB`;
  //   } if (size >= 1000) {
  //     value = (Math.floor(size / 10) / 100);
  //     return `${value} GB`;
  //   }
  //   return `${Math.floor(size)} MB`;
  // };
  // let flow;
  const checkSize = (size) => {
    if (size <= 0) {
      return '0 MB';
    }
    const bytes = size * 1048576;
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / (1024 ** i)).toFixed(2))} ${units[i]}`;
  };

  const changeDateFormat = (date) => {
    if (date) {
      const [year, month, day] = date.split('-');
      return (`${month}/${day}/${year}`);
    }
  };

  const getDateDifference = (endDate) => {
    const from = moment(new Date());
    const to = moment(changeDateFormat(endDate));
    // return to.subtract(1, 'days').diff(from, 'days');
    return from.diff(to, 'days');
  };

  const calculations = async () => {
    if (subscriptionDetails?.storageUsed && userDetails?.totalContentUploaded) {
      // const usedStorage = 0;
      const usedStorage = Math.ceil(subscriptionDetails?.storageUsed?.used_storage);
      // eslint-disable-next-line radix
      const maxStorage = parseInt(subscriptionDetails?.storageUsed?.total_storage);

      const availableStorage = maxStorage - usedStorage;
      const availableStoragePercentage = (Math.floor((availableStorage / maxStorage) * 100) < 98)
        || (Math.floor((availableStorage / maxStorage) * 100) === 100)
        ? Math.floor((availableStorage / maxStorage) * 100) : 98;
      const usedStoragePercentage = (Math.ceil((usedStorage / maxStorage) * 100) > 2)
        || (Math.ceil((usedStorage / maxStorage) * 100) === 0)
        ? Math.ceil((usedStorage / maxStorage) * 100) : 2;

      const usedVideoStorage = Math.ceil(subscriptionDetails?.storageUsed?.video_storage);
      const usedVideoStoragePercentage = (Math.ceil((usedVideoStorage / maxStorage) * 100) > 2)
        || (Math.ceil((usedVideoStorage / maxStorage) * 100) === 0)
        ? Math.ceil((usedVideoStorage / maxStorage) * 100) : 2;
      const usedPhotoStorage = Math.ceil(subscriptionDetails?.storageUsed?.album_storage);
      const usedPhotoStoragePercentage = (Math.ceil((usedPhotoStorage / maxStorage) * 100) > 2)
        || (Math.ceil((usedPhotoStorage / maxStorage) * 100) === 0)
        ? Math.ceil((usedPhotoStorage / maxStorage) * 100) : 2;
      setStorageDetails({
        max: checkSize(maxStorage),
        used: checkSize(usedStorage),
        available: checkSize(availableStorage),
        availablePercentage: availableStoragePercentage,
        usedPercentage: usedStoragePercentage,
        usedVideoPercentage: usedVideoStoragePercentage,
        usedPhotoPercentage: usedPhotoStoragePercentage,
        usedVideoStorage: checkSize(usedVideoStorage),
        usedPhotoStorage: checkSize(usedPhotoStorage),
        allowedViewers: subscriptionDetails?.subscription?.is_unlimited === '1' ? 'Unlimited' : subscriptionDetails?.subscription?.max_view_request || null,
        viewer: subscriptionDetails?.total_viewers,
        planName: subscriptionDetails?.subscription?.product_name,
        upgradePlanName: subscriptionDetails?.is_addon_allowed ? subscriptionDetails?.action : subscriptionDetails?.upgradePlanDetails?.length ? subscriptionDetails?.upgradePlanDetails?.[0]?.product_name : null,
        upgradePlanViewers: subscriptionDetails?.is_addon_allowed ? (subscriptionDetails?.subscription?.is_unlimited === '1' ? 'Unlimited' : subscriptionDetails?.subscription?.max_view_request)
          : subscriptionDetails?.upgradePlanDetails?.length ? (subscriptionDetails?.upgradePlanDetails?.[0]?.is_unlimited === '1' ? 'Unlimited' : subscriptionDetails?.upgradePlanDetails?.[0]?.max_view_request)
            : null,
        upgradePlanStorage: subscriptionDetails?.is_addon_allowed ? checkSize(parseFloat(subscriptionDetails?.subscription?.total_storage) + parseFloat(subscriptionDetails?.subscription?.max_add_on_allowed)) : subscriptionDetails?.upgradePlanDetails?.length ? checkSize(parseFloat(subscriptionDetails?.upgradePlanDetails?.[0]?.storage)) : null,
      });
    } else {
      setStorageDetails({
        max: '0 TB',
        used: '0 MB',
        available: '0 MB',
        availablePercentage: 100,
        usedPercentage: 0,
        usedVideoPercentage: 0,
        usedPhotoPercentage: 0,
        usedVideoStorage: 0,
        usedPhotoStorage: 0,
        viewer: 0,
        planName: '',
        upgradePlanName: '',
        upgradePlanViewers: '',
        upgradePlanStorage: '',
        allowedViewers: '',
      });
    }

    // set remaining days for referral free plan
    if (subscriptionDetails?.benefit_days && subscriptionDetails?.billing_start_date) {
      setRemainingDays(parseInt(subscriptionDetails?.benefit_days, 10) - getDateDifference(subscriptionDetails?.billing_start_date?.split(' ')[0]));
    }
    // check if storage exceeded
    if (isStorageFullCondition) {
      setStorageFull(true);
    }
  };

  useEffect(() => {
    calculations();
  }, [subscriptionDetails]);

useEffect(() => {
  setHelpNeed(false);
  return () => {
    setHelpNeed(true);
  };
}, []);

  const [accessRequests, setAccessRequests] = useState([]);
  const [hasAccess, setHasAccess] = useState([]);
  const [videos, setVideos] = useState([]);

  const [snackbar, setSnackbar] = useState({ isVisible: false, message: '' });
  const [pendingViewersList, setPendingViewersList] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [selectedFile, selectFile] = useState(false);
  const { isValidating: loadingAccessRequests, mutate: fetchAccessRequests } = useSWR([endpoints.getAllViewRequest], {
    fetcher: (url, status) => dataFetcher(url, { status }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setAccessRequests(data?.filter(({ status }) => (status === '0')));
        setHasAccess(data?.filter(({ status }) => (status === '1')));
      } else {
        setAccessRequests([]);
      }
    },
    onError: () => {
      setAccessRequests([]);
    },
  });
  const spinerSmall = 'spinex';
  const { isValidating: fetchingPendingList, mutate: fetchPendingList } = useSWR([
    endpoints.getPendingViewersList], {
    fetcher: (url, search, status) => dataFetcher(url, { search, status }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setPendingViewersList(data);
      } else {
        setPendingViewersList([]);
      }
    },
    onError: () => {
      setPendingViewersList([]);
    },
  });

  const { isValidating: fetchingViewers, mutate: fetchViewerList } = useSWR([
    endpoints.getViewersList], {
    fetcher: (url, search, status) => dataFetcher(url, { search, status }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setViewers(data);
      } else {
        setViewers([]);
      }
    },
    onError: () => {
      setViewers([]);
    },
  });

  const { isValidating: loadingVideos, mutate: fetchVideos } = useSWR(endpoints.getMyVideosList, {
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setVideos(data);
      } else {
        setVideos([]);
      }
    },
    onError: () => {
      setVideos([]);
    },
  });

  useEffect(() => {
    fetchVideos();
    setNeedToShowETA(true);
    fetchPendingList();
    fetchViewerList();
  }, []);

  useEffect(() => {
    if (isViewerListUpdated) {
      fetchPendingList();
      fetchViewerList();
      setViewerListUpdate(false);
    }
  }, [isViewerListUpdated]);

  // useEffect(() => {
  //   let interval;
  //   if (location.pathname?.includes(Pathname.dashboard)) {
  //     interval = setInterval(() => {
  //       fetchPendingList();
  //       fetchViewerList();
  //     }, 1000);
  //   } else {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [location.pathname]);
  useEffect(() => {
    if (!localStorage.getItem('id')) {
      autoSignIn();
    } else {
      const databaseRefFetchRequests = ref(firebaseDatabase, `friend_requests_updates/${localStorage.getItem('id')}`);
      const dataCallbackReq = (snapshot) => {
        if (snapshot.exists()) {
          fetchPendingList();
          fetchViewerList();
        }
      };
      onValue(databaseRefFetchRequests, dataCallbackReq);
      return () => {
        off(databaseRefFetchRequests, 'value', dataCallbackReq);
      };
    }
  }, []);
  useEffect(() => {
    getSubscription();
    autoSignIn();
    setTopOrBottom(true);
    return () => {
      setTopOrBottom(false); // Set topOrBottom to false when leaving the component
    };
  }, []);
  const revealUploadModal = () => {
    setUploaderLoader(true);
    autoSignIn().then((res) => {
      if (!res?.data?.subscription && res?.data?.totalContentUploaded >= ALLOWED_UPLOADS_COUNT) {
        setPopUpModalVisible(true);
        setStorageFull(true);
      } else {
        navigate(`${location.pathname}${Pathname.uploadVideo}`);
      }
    });
    // if (isStorageFullCondition) {
    //   setStorageFull(true);
    // } else {
    //   navigate(`${location.pathname}${Pathname.uploadVideo}`);
    // }
  };
  const hidepopModal = () => { setPopUpModalVisible(false); };
  return (
    <Page contentClassName={[styles.pageContent, styles.contentWrapper]} headerTheme={styles.headerTheme}>
      <div className={styles.dashboard}>
        <Text.PageTitle>{'Content Dashboard' || contentDashboard?.title}</Text.PageTitle>
        <Box position="relative">
        <VideoPopupModal isVisible={popUpModalVisible} onHide={hidepopModal} />
          {
            gettingSubscription ? (
              <ProgressBar />
              // <ProjectorShimmer isLight />
              // <div className={styles.circularLoader}>Loading...</div>
            ) : (
              isInActiveUser ? (
                <div className={styles.needYourAttention}>
                  <div className={styles.needYourAttentionBox}>
                    <div className={styles.needAttentionTitle}>
                      {contentDashboard?.needYourAttention || 'Need your Attention'}
                    </div>
                    <div className={styles.attentionDescription}>
                    {/* {contentDashboard?.attentionDescription || 'Due to technical glitch, we were unable to renew your subscription. Please make sure the payment method is updated and retry the renewal manually'} */}
                      Your payment could not be processed at this time. Please contact billing@projectorstream.com for assistance.
                    </div>
                    <div className={styles.updatePaymentButton} onClick={() => navigate(Pathname.getSettingsPath(Pathname.settings.billings))}>
                      {contentDashboard?.updatePaymentButton || 'Update payment method'}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.sectionsContainer}>
                  <section className={cn({ [styles.section]: true, [styles.videoUploadSection]: true })}>
                    <div className={styles.videoUploadSectionContent}>
                      <Grid container>
                        <Grid item xs={5} sm={5} className={styles.iconColumn}>
                          <UploadIcon className={cn({ [styles.uploadIcon]: true, [styles.videoUploadIconPosition]: true })} />
                        </Grid>
                        <Grid item xs={2} sm={2} className={styles.lineStyle}>
                          <VerticalLine className={styles.lineStyle} />
                        </Grid>
                        <Grid item xs={5} sm={5} className={styles.iconColumn}>
                          <ImageUploadIcon className={cn({ [styles.uploadIcon]: true, [styles.albumUploadIconPosition]: true })} />
                        </Grid>
                      </Grid>
                      {/* <div>
                      <UploadIcon className={styles.uploadIcon} />
                      <VerticalLine className={styles.lineStyle} />
                      <ImageUploadIcon className={styles.uploadIcon} />
                    </div> */}
                      <Button
                        disabled={uploaderLoader === true}
                        className={styles.uploadButton}
                        onClick={() => revealUploadModal()}
                      >
                        {contentDashboard?.upload || 'upload'}
                        {' '}
                        {uploaderLoader === true
                      && (<Spinner spinex={spinerSmall} />)}
                      </Button>
                    </div>
                  </section>
                  <section className={cn({ [styles.section]: true, [styles.videoAnalyticsSection]: true })}>
                    <SectionTitle
                      title="Storage"
                      action={remainingDays && remainingDays > 0 ? () => <div className={styles.remainingDays}>{`${remainingDays} Free Days`}</div> : null}
                    />

                    <div>
                      <div className={styles.storageInfoText}>
                        {'Current Plan : '}
                        {subscriptionDetails?.subscription?.is_trial === '1'
                        ? 'Unlimited Storage (trial)'
                        : userDetails?.isSubscription
                            ? 'Unlimited Storage'
                            : `${ALLOWED_UPLOADS_COUNT} Free Upload`}
                      </div>
                      <Box display="flex" marginTop="5px" borderRadius="10px" bgcolor="red">
                        <Box
                          bgcolor="#5aa5ef"
                          width="50%"
                          borderRadius="10px 0px 0px 10px"
                        >
                          <p
                            className={styles.storageBarText}
                          >
                            {`Videos ${storageDetails.usedVideoStorage}`}
                          </p>
                        </Box>

                        <Box
                          bgcolor="#00D35A"
                          width="50%"
                          borderRadius="0px 10px 10px 0px"
                        >
                          <p
                            className={styles.storageBarText}
                          >
                            {`Photos ${storageDetails.usedPhotoStorage}`}
                          </p>
                        </Box>
                      </Box>
                      <div className={styles.storageInfoBar}>
                        <span className={styles.storageInfoText}>
                          {userDetails?.totalVideoUploaded || ' '}
                          {' '}
                          {contentDashboard?.videos || 'Videos'}
                        </span>
                        <span className={styles.storageInfoText}>{`:  ${storageDetails.usedVideoStorage}`}</span>
                      </div>
                      <div className={styles.storageInfoBar}>
                        <span className={styles.storageInfoText}>
                          {userDetails?.totalPhotoUploaded || ' '}
                          {' '}
                          {contentDashboard?.photoAlbums || 'Photo Albums'}
                        </span>
                        <span className={styles.storageInfoText}>{`:  ${storageDetails.usedPhotoStorage}`}</span>
                      </div>
                      <div className={styles.manageWrapper}>
                        <div
                          onClick={() => { navigate(Pathname.getSettingsPath(Pathname.settings.billings)); }}
                          className={styles.storageManagementButton}
                        >
                          {contentDashboard?.manage || 'Manage'}
                        </div>
                      </div>
                      <br />
                      {/* {
                      subscriptionDetails?.pending_reff_bonus ? (
                        <div className={styles.referralRewardmsg}>
                          {subscriptionDetails?.pending_reff_bonus}
                        </div>
                      ) : null
                    } */}
                      {/* <button className={styles.storageManagementButton}>Manage</button> */}
                    </div>
                    {/* <SectionTitle title={contentDashboard?.videoAnalytics || 'Video Analytics'} /> */}
                    {/*
                      !subscriptionDetails?.is_addon_allowed && !subscriptionDetails?.upgradePlanDetails?.length ? null
                        : (
                          <div className={styles.upgradeSection}>
                            <div className={styles.detailsPart}>
                              <div className={styles.storageInfoText}>
                                {storageDetails.upgradePlanName}
                              </div>
                              <div className={styles.storageInfoBar}>
                                <span className={styles.storageInfoText}>{contentDashboard?.storage || 'Storage'}</span>
                                <span className={styles.storageInfoText}>{`:  ${storageDetails.upgradePlanStorage}`}</span>
                              </div>
                              <div className={styles.storageInfoBar}>
                                <span className={styles.storageInfoText}>{contentDashboard?.viewers || 'Viewers'}</span>
                                <span className={styles.storageInfoText}>{`:  ${storageDetails.upgradePlanViewers}`}</span>
                              </div>
                            </div>
                            <div className={styles.actionPart}>
                              <div className={styles.manageWrapper}>
                                <div
                                  onClick={() => { navigate(Pathname.plans); }}
                                  className={styles.upgradeButton}
                                >
                                  {contentDashboard?.upgrade || 'Upgrade'}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        */}
                    {/* <div className={styles.referFriendWrapper}>
                    <div className={styles.referText}>
                      {contentDashboard?.referAFriendTitle1 || 'Refer a Friend for'}
                      <br />
                      {contentDashboard?.referAFriendTitle2 || 'TWO FREE MONTHS'}
                    </div>
                    <Link to={Pathname.referFriend}>
                      <div className={styles.inviteButtonWrapper}>
                        <div className={styles.inviteButton}>
                          {commonText?.invite || 'Invite'}
                        </div>
                      </div>
                    </Link>
                  </div> */}
                    {
                      subscriptionDetails?.pending_reff_bonus_days ? (
                        <div className={styles.redeemCreditWrapper}>
                          <div className={cn({ [styles.storageInfoText]: true, [styles.textAlign]: true })}>
                            {`Redeem Referral Credits : ${subscriptionDetails?.pending_reff_bonus_days} Days`}
                          </div>
                          <div
                            onClick={() => { navigate(Pathname.plans); }}
                            className={styles.upgradeButton}
                          >
                            {contentDashboard?.redeem || 'Redeem'}
                          </div>
                        </div>
                      ) : null
                    }
                  </section>
                  <section className={cn({ [styles.section]: true, [styles.userManagementSection]: true })}>
                    <SectionTitle
                      title={'Pending Viewers' || contentDashboard?.viewersPending}
                      action={() => <Link className={styles.addUserButton} to={Pathname.grantAccess}>+</Link>}
                    />
                    <div className={styles.viewerList} style={{ maxHeight: '100px' }}>
                      {pendingViewersList?.map(({ id, firstname, lastname, email, type }, idx) => (
                        <UserRequest
                          email={email}
                          id={id}
                          marginDown
                          key={idx}
                          firstname={firstname}
                          lastname={lastname}
                          setSnackbar={setSnackbar}
                          fetchAccessRequests={fetchPendingList}
                          fetchViewerList={fetchViewerList}
                          setAccessRequests={setPendingViewersList}
                          setHasAccess={setViewers}
                          type={type}
                        />
                      ))}
                    </div>
                    {
                      !pendingViewersList?.length ? (
                        <div className={styles.accessRequestNotes}>{contentDashboard?.noNewReq || 'No new requests'}</div>
                      ) : null
                    }
                    <br />
                    {' '}
                    {/* All Viewers */}
                    <SectionTitle
                      title={'Active Viewers' || contentDashboard?.allViewers}
                    />
                    <div className={styles.viewerList}>
                      {viewers?.map(({ id, firstname, lastname, email }, idx) => (
                        <UserRequest
                          email={email}
                          id={id}
                          key={idx}
                          firstname={firstname}
                          lastname={lastname}
                          setSnackbar={setSnackbar}
                          hasAccess
                          fetchAccessRequests={fetchPendingList}
                          fetchViewerList={fetchViewerList}
                          // fetchAccessRequests={fetchViewerList}
                          setAccessRequests={setViewers}
                          setHasAccess={setViewers}
                        />
                      ))}
                    </div>
                    {
                      !viewers?.length ? (
                        <div className={styles.accessRequestNotes}>{contentDashboard?.noViewers || 'No viewers'}</div>
                      ) : null
                    }
                  </section>
                </div>
              )
            )
          }
        </Box>
      </div>
      <Snackbar {...snackbar} onClose={() => { setSnackbar({ ...snackbar, isVisible: false }); }} />
    </Page>
  );
};

// export const Dashboard = MemoDash;
