import { Box, Button, ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './plansCard.module.css';
import markBlack from '../Assets/mark-black.svg';
import TickCircle from '../Assets/check-circle.png';

function PlansCard({ product_name, amount, has_trial, currency, subtitle, description, price_description }) {
    return (
        <Box>
            <div className={styles.planHead}>
                <div className={styles.planName}>
                    <p>{product_name}</p>
                    {/* <span>{subtitle}</span> */}
                </div>

                <div className={styles.planPrice}>
                    <p>
                        $
                        {amount}
                    </p>
                    <span>{price_description}</span>
                </div>
            </div>

            <ul className={styles.planBody}>
                { description?.split(',').map((feature, index) => (
                        <li key={index}>
                            <span className={styles.feat}>{feature}</span>
                            <img className={styles.tickster} src={TickCircle} alt="tick_mark" />
                        </li>
                      ))}

            </ul>

            { has_trial === '1' && (
                <Link to="/newSign-up" className={styles.trail}>
                    Get Started For Free
                </Link>
            )}
            <Link
                to="/newSign-up"
                className={styles.buyNow}
            >
                Buy Now
            </Link>
        </Box>
    );
}

export default PlansCard;
