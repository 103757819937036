/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useContext, useEffect } from 'react';
import logo from 'Assets/logo_round.png';
import { AppContext, AuthContext } from 'Context';
import AddDotLoader from 'Pages/private/videos/AddDotLoader';
import { axios, endpoints, baseUrl } from 'Api';
import styles from './index.module.css';

function PriceSectionLayout({
  planName,
  planPrice,
  planId,
  intervalInDays,
  navigation,
  promoCode,
  promoErrMsg,
  setPromoErrMsg,
  setPromoCodeNew,
  promoCodeNew,
  promoLoading,
  setPromoLoading
}) {
  const { affiliateCode, setAffiliatedCode, setIsTrail, isTrail } = useContext(AppContext);
  const [manager, setManager] = useState(null);
  const [active, setActive] = useState(false);
  const [promoActive, setPromoActive] = useState(false);
  const [promoResponse, setPromoResponse] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState('');
  const { getToken } = useContext(AuthContext);

  const calculateDiscountedPrice = (price) => {
    const priceInDollar = price / 100;
    const discountedPrice = planPrice?.amount ? (planPrice?.amount - priceInDollar) : (planPrice - priceInDollar);
    const formattedDiscountedPrice = discountedPrice.toFixed(2);
    setDiscountedPrice(formattedDiscountedPrice);
  };

  const handleAffiliatedCodeChange = async (event) => {
    const typedData = event.target.value;
    setAffiliatedCode(typedData);
    setManager('');
    if (event.target.value?.length > 4) {
      setActive(true);
      try {
        axios({
          url: endpoints.getAffiliateName,
          method: 'POST',
          data: { code: typedData, token: getToken() },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          setManager(`${response.data?.data?.firstname} ${response.data?.data?.lastname}`);
        });
      } catch (error) {
        console.error('Error:', error);
      }
    } else if (event.target.value < 5) {
      setActive(false);
    }
  };

  const handlePromoCodeChange = async (e) => {
    const newPromoCode = e.target.value?.toUpperCase();
    setPromoCodeNew(newPromoCode);

    if (newPromoCode?.length >= 6 && newPromoCode?.length <= 14) {
      setPromoActive(true);
      try {
        setPromoLoading(true);
        const response = await axios({
          url: `${baseUrl}/getPromoAmount`,
          method: 'POST',
          data: { token: getToken(), promo_code: newPromoCode, plan_id: planId },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setPromoResponse(response.data);
        calculateDiscountedPrice(response.data?.data?.amount);
        setPromoErrMsg('');
        setPromoLoading(false);
      } catch (error) {
        setPromoErrMsg(error?.response?.data?.message);
        setPromoResponse();
        setPromoLoading(false);
        setDiscountedPrice('');
      }
    } else if (newPromoCode?.length < 6) {
      setPromoActive(false);
      setDiscountedPrice('');
    }
  };

  useEffect(() => {
    setAffiliatedCode(localStorage.getItem('code'));
    return () => {
      setIsTrail('0'); // This will run when the component unmounts
    };
  }, []);

  return (
    <>
      <div className={styles.mainDiv}>
        {isTrail === '1'
          && (
            <p className={styles.title}>
              Projector Annual Subscription
              <br />
              You will be charged in
              {' '}
              {planPrice?.total_trial_days}
              {' '}
              {planPrice?.total_trial_days === '1' ? 'day' : 'days'}
            </p>
          )}
        <p className={styles.subTitle}>{`$${planPrice?.amount ? planPrice?.amount : planPrice}/year`}</p>
        <div className={styles.logoDiv}>
          <div className={styles.logoDivImg}>
            <img width="50px" src={logo} />
          </div>
          <div className={styles.projectorTitleDiv}>
            <p className={styles.projectorTitle}>Projector</p>
            <p className={styles.projectorSubTitle}>Billed Annually</p>
            <p className={styles.subTotal}>Subtotal</p>
          </div>
          <div className={styles.projectorPriceDiv}>
            <p className={styles.price}>{`$${planPrice?.amount ? planPrice?.amount : planPrice}`}</p>
            <p style={{ marginTop: '23px' }} className={styles.price}>{`$${planPrice?.amount ? planPrice?.amount : planPrice}`}</p>
          </div>
        </div>
        {/* <div style={{ display: 'flex' }}>
        </div> */}
        {/* {navigation !== '/settings/manage-users' && ( */}
        {localStorage.getItem('code') ? (
          <p>{localStorage.getItem('code')}</p>
        ) : (
          <>
            <input
              className={styles.affiliatedCode}
              value={affiliateCode}
              onChange={handleAffiliatedCodeChange}
              type="number"
              disabled={promoCode}
              placeholder={promoCode || 'Add Affiliate code'}
            />
            {isTrail !== '1' && (
              <input
                className={styles.affiliatedCode}
                value={promoCodeNew}
                onChange={handlePromoCodeChange}
                placeholder="Add Promo code"
                maxLength={14}
              />
            )}
          </>
        )}
        {promoCodeNew?.length > 0 && promoCodeNew?.length < 6 && (
          <p className={styles.promoCode} style={{ color: '#bb2124' }}>
            *Promo code requires at least 6 characters!
          </p>
        )}
        {promoActive && (
          promoLoading ? (
            <p className={styles.promoCode}><AddDotLoader text="" /></p>
          ) : !promoErrMsg ? (
            <p className={styles.promoCode} style={{ color: '#22bb33' }}>{`${promoResponse?.data?.name} Applied!`}</p>
          ) : (
            <p className={styles.promoCode} style={{ color: '#bb2124' }}>{promoErrMsg}</p>
          )
        )}
        {manager
          ? (
            <p className={styles.mangerx}>
              Manger Name:
              {' '}
              {manager}
            </p>
          )
          : active === true
            ? (<p className={styles.invalid}>Invalid Code</p>)
            : null}
        {isTrail === '1' && (
          <div className={styles.regularDate}>
            <p className={styles.projectorTitle}>Regular Rate </p>
            <p className={styles.totalPrice} style={{ textDecoration: 'line-through' }}>{`$${planPrice?.amount ? planPrice?.amount : planPrice}`}</p>
          </div>
        )}
        {(isTrail !== '1' && discountedPrice !== '') && (
          <div className={styles.regularDate}>
            <p className={styles.projectorTitle}>Regular Rate </p>
            <p className={styles.totalPrice} style={{ textDecoration: discountedPrice !== '' && 'line-through' }}>{`$${planPrice?.amount ? planPrice?.amount : planPrice}`}</p>
          </div>
        )}
        <div className={styles.total}>
          <p className={styles.projectorTitle}>Total Due Today </p>
          {isTrail === '1' ? (<p className={styles.totalPrice}>$0.00</p>)
            : (
              <p className={styles.totalPrice}>{`$${discountedPrice !== '' ? discountedPrice : planPrice?.amount || planPrice}`}</p>
            )}
        </div>
      </div>
    </>
  );
}

export default PriceSectionLayout;
